import React, { useState } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";

const FilterRange = (props) => {
    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        filterBySelectedValues,
    } = props;
    let filterBtnsSelectedValues = Array.from(filterBtnsSelected[ele.accessor]);
    if (filterBtnsSelectedValues.length == 1) {
      filterBtnsSelectedValues.push(filterBtnsSelectedValues[0]);
    }
    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const range = ele?.range;
    const [defaultValue, setDefaultValue] = useState(filterBtnsSelectedValues);

    const sliderStyle = {
        // Give the slider some width
        position: "relative",
        bottom: "-10px",
        width: "88%",
        left: "-5%",
        height: 40,
        float: "right",
    };

    const railStyle = {
        position: "absolute",
        width: "100%",
        height: 10,
        marginTop: 15,
        borderRadius: 5,
        backgroundColor: "#BEC6D2",
    };

    const onChange = (item) => {
      let copySet = new Set(item);
      filterBySelectedValues({ [ele.name]: copySet });
  };

    const Handle = function ({ handle: { id, value, percent }, getHandleProps }) {
        return (
          <div
            style={{
              left: `${percent}%`,
              position: "absolute",
              marginLeft: -15,
              marginTop: 7.4,
              zIndex: 2,
              width: 25,
              height: 25,
              border: 0,
              textAlign: "center",
              cursor: "pointer",
              borderRadius: "50%",
              backgroundColor: "#2C4870",
              color: "#333",
            }}
            {...getHandleProps(id)}
          >
            <div style={{ fontFamily: "sans-serif", fontSize: 11, marginTop: -20, fontWeight: "bold", whiteSpace: "nowrap" }}>
              {value}
            </div>
          </div>
        );
      };
    
      const Track = function ({ source, target, getTrackProps }) {
        return (
          <div
            style={{
              position: "absolute",
              height: 10,
              zIndex: 1,
              marginTop: 15,
              backgroundColor: "#546C91",
              borderRadius: 5,
              cursor: "pointer",
              left: `${source.percent}%`,
              width: `${target.percent - source.percent}%`,
            }}
            {
              ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
            }
          />
        );
      };

    const popover = (
      <Popover id="popover-basic" style={{width: "100%", height: "80px"}}>
        <Popover.Body>
          <Slider
              rootStyle={sliderStyle}
              onChange={onChange}
              domain={[range[0][accessor], range[1][accessor]]}
              step={1}
              mode={2}
              values={[defaultValue[0], defaultValue[1]]}
              >
              <Rail>
                  {({ getRailProps }) => (
                  <div style={railStyle} {...getRailProps()} />
                  )}
              </Rail>
              <Handles>
                  {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                      {handles.map((handle) => (
                      <Handle
                          key={handle.id}
                          handle={handle}
                          getHandleProps={getHandleProps}
                      />
                      ))}
                  </div>
                  )}
              </Handles>
              <Tracks left={false} right={false}>
                  {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                      {tracks.map(({ id, source, target }) => (
                      <Track
                          key={id}
                          source={source}
                          target={target}
                          getTrackProps={getTrackProps}
                      />
                      ))}
                  </div>
                  )}
              </Tracks>
            </Slider>
        </Popover.Body>
      </Popover>
    );

    const doNothing = () => {};

    return (<div
        key={ele.name}
        title={ele.label}
        className={
          `filter-name filter-range ${ele.advance ? "filter-advanced" : ""} ${ele.name}-label-filter-name` +
          (selected ? "selected" : "") +
          (ele.disabled ? " disabled" : "")
        }
      >
        {defaultValue.length ? <div className="name-top">{ele.label}</div> : ""}
        <OverlayTrigger toggle="popover" trigger="focus" placement="bottom" overlay={popover}>
          <Form.Control value={defaultValue.join(' - ')} onChange={doNothing} className="p-2"/>
        </OverlayTrigger>

        {/*<Slider
            rootStyle={sliderStyle}
            onChange={onChange}
            domain={[range[0][accessor], range[1][accessor]]}
            step={1}
            mode={2}
            values={[defaultValue[0], defaultValue[1]]}
            >
            <Rail>
                {({ getRailProps }) => (
                <div style={railStyle} {...getRailProps()} />
                )}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                    {handles.map((handle) => (
                    <Handle
                        key={handle.id}
                        handle={handle}
                        getHandleProps={getHandleProps}
                    />
                    ))}
                </div>
                )}
            </Handles>
            <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                    <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                    />
                    ))}
                </div>
                )}
            </Tracks>
                    </Slider>*/}
      </div>);
};

export default FilterRange;