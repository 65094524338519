import React from "react";
import Filters1 from "./Filters1";

import "../Header/header.scss";
import BreadcrumbView from "../Header/BreadcrumbView";

const Header = (props) => {
    return (<>
        <div fluid>
            <hr className='section-divider'/>
            <BreadcrumbView 
                breadcrumbConfig={props.breadcrumbConfig} 
                refreshConfig={props?.refreshConfig || {}} 
                updateData={props.setFilterBtnsSelected} 
                filterBtnsSelected={props.filterBtnsSelected} 
                showSavedFilters={props?.showSavedFilters} 
                showLandscapeSection={props?.showLandscapeSection} 
                moreData={props?.moreData} 
                hideBreadCrumb={props.hideBreadCrumb}
                hideRefresh={props.hideRefresh}
            />
        </div>
        {!props.onlyBreadCrumb ? 
            <div id="header-container" fluid>
                <Filters1 {...props} />
                <hr className='section-divider'/>
            </div> : ""
        }
    </>);
};
export default Header;