export const loginPage = "/";
export const passThrough = "/passThrough";
export const signUpPage = "/register";
export const tempPage = "/temp";
export const errorPage = "/error";

export const dashboardPage = "/dashboard";
export const dashboardPricingPage = "/dashboardPricing";
export const productsPricingPage = "/productsPricing";
export const competitorPricingPage = "/competitorPriceChangeHistory";
export const productPricingPage = "/productsPricing/:id";
export const compProductPricingPage = "/compProductsPricing/:parentSku/:seller/:id";
export const productGapsPage = "/productGaps";
export const productGapsUniqueByRetailersPage = "/uniqueByRetailersGaps";
export const brandGapsPage = "/brandGaps";
export const productsGroupPricingPage = "/productsGroupPricing";
// Actioning
//  1. Suppression
export const productSupressedGapsPage = "/product/suppressed";
export const brandSupressedGapsPage = "/brand/suppressed";
//  2. Onboarding
export const productOnboardingGapsPage = "/product/toOnboard";
export const brandOnboardingGapsPage = "/brand/toOnboard";
export const categoryPricingPage = "/categoryPricing";
export const priceLeaderAssortmentPage = "/priceLeaderAssortment";
export const priceLeaderBrandAssortmentPage = "/priceLeaderBrandAssortment";
export const priceLeaderPricingtPage = "/priceLeaderPricing";
export const priceLeaderBrandPricingtPage = "/priceLeaderBrandPricing";
export const editCategoryPage = "/categoryEdited";
export const userGuidePage = "/userGuide";
export const userGuideEditPage = "/userGuide/edit";
export const FavoriteProductPage = "/product/watchlist";
export const FavoriteBrandPage = "/brand/watchlist";
export const reportsPage = "/reports";
export const tpvrSummaryPage = "/tpvrSummary";
export const matchCoveragePage = "/reports/matchCoverage";
export const matchOpportunitiesPage = "/reports/matchOpportunities";
export const assortmentWowPage = "/reports/assortmentWow";
export const matchCoverageCategoryPage = "/reports/matchCoverageCategory";
export const matchCoverageRetailerePage = "/reports/matchCoverageRetailer";
export const matchCoveragePriorityPage = "/reports/matchCoveragePriority";
export const dailyFeedsPage = "/dailyFeeds";
export const matchesPage = "/reports/matches";
export const pricingPage = "/reports/pricing";
export const matchReportsPage = "/matchReports";
export const matchReportsTpvrPage = "/reports/matchReportsTpvr";
export const matchReportsTpvrManagerPage = "/reports/matchReportsTpvrManager";
export const matchReportsSlaPage = "/matchReportsSla";
export const pricingSummaryPage = "/reports/pricingSummary";
export const pricingApproveRejectSummaryPage = "/reports/pricingApproveRejectSummary";
export const NormalizedBrands = '/normalizedBrands'
export const pricingRulesPage = '/pricingRules';
export const pricingRulesUpdatePage = '/pricingRules/:id/:version';
export const pricingRulesListPage = '/pricingRulesList';
export const pricingRulesGroupListPage = '/pricingRulesGroupList';
export const pricingSystemRulesPage = '/pricingRulesSystemRules';
export const pricingSuppressionsNav = '/pricing-suppressions'
export const pricingProductSuppressionsListPage = '/pricing-rule-suppressions'
export const productsPricingRulesPage = '/productsPricingRules';
export const productPricingRulePage = "/productsPricingRule/:id";
export const pricingRulesHistoryPage = '/pricingRulesHistory';
export const productsPricingSalesPage = '/productsPricingSales';
export const productsPricingWeightedCpiPage = '/productsPricingWeightedCpi';
export const offlineFeedsPage = '/reports/offlinefeeds'
export const pricingFeedsPage = '/reports/pricingfeeds'
export const outlierReportsPage = '/reports/outlierreport'
export const priceChangeReportsPage = '/reports/pricechangereport'
export const suggestionsReportsPage = '/reports/suggestionreport'
export const executiveSummaryReportsPage = '/reports/executivesummary'
export const tpvrReportsPage = "/reports/tpvr";
export const priceComparisonReportsPage = "/reports/priceComparison";
export const notificationPage = '/notifications';