import React from "react";

const ErrorPage = () => {
    return (<div className="page-container-default">
        <div className="custom-container">
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <h1 className="display-1 fw-bold text-dark">Error</h1>
                    <p className="fs-3"> <span className="text-danger">Oops! It seems we're experiencing some technical difficulties at the moment. Our team is working hard to resolve the issue. Please try again later. Thank you for your patience and understanding.</span></p>
                </div>
            </div>
        </div>
    </div>);
};

export default ErrorPage;