import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const FilterBoolean = (props) => {
    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        filterBySelectedValues,
    } = props;
    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const range = ele?.range;
    const [defaultValue, setDefaultValue] = useState(Array.from(filterBtnsSelected[accessor])[0]);

    const changeCheckBox = (checkValue) => {
        setDefaultValue(checkValue);
        let copySet = new Set([checkValue]);
        filterBySelectedValues({ [ele.name]: copySet });
    };
    
    return (<div
        key={ele.name}
        className={
          `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele.name}-label-filter-name` +
          (selected ? "selected" : "") +
          (ele.disabled ? " disabled" : "")
        }
      >
          <div className="name-top-boolean">{ele.label}</div>
          <Form.Check 
            size="lg"
            type="switch" 
            checked={defaultValue}
            onChange={e => {
                changeCheckBox(e.target.checked);
            }}
          />
      </div>);
};

export default FilterBoolean;