import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, Table, Alert, ButtonGroup, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Header1 from "../common/Header1/Header1";
import ReactPlotly from "../common/ReactPlotly";
import Loader from "..//common/Loader";
import { toast } from 'react-toastify';

//import ListGroup from "react-bootstrap/ListGroup";
//import ListGroupItem from "react-bootstrap/ListGroupItem";
//import Table from "react-bootstrap/Table";
//import assortmentGapsMockData from "./assortmentGapsMockData";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowCircleDown,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowRight,
    faLink,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import * as pageRoutes from "../../pageRoutes";
import { apiCall, boolImgRetailer, getReviewCountMaxSuggestions, getReviewCountMinSuggestions, getReviewCountSuggestions, isFilterAllowed, numberWithCommas,getLatestRefreshDate } from "../common/common.js";
import {
    ApiFailedError,
    NoDataMessage,
    assortmentKpiApi,
} from "../common/constants.js";

import { getQueryParams, generateFilters, triggerFilterEvent } from "../../utils/routeBasedOperations";

import productRetailerGapsMockData from "./productRetailerGapsMockData";
import ProductRetailerGapsGraph from "./ProductRetailerGapsGraph";
import ProductRetailerGapsPieChart from "./ProductRetailerGapsPieChart";

import ProductGapsTableGraph from "./ProductGapsTableGraph";
import BrandGapsTableGraph from "./BrandGapsTableGraph";
import ProductBrandGapsTableGraph from "./ProductBrandGapsTableGraph";

import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import CategoryGraphSection from "./CategoyGraphSection";
import BreadcrumbView from "../common/Header/BreadcrumbView";
import { getLocalStorageValue } from "../../utils/localStorageOperations";

//import "./dashboard.scss"; 

const Dashboard = () => {

    const [selectedProductRetailerGapRetailer, setSelectedProductRetailerGapRetailer] = useState("");
    const [breadcrumbConfig, setBreadcrumbConfig] = useState([{
        href: "/",
        title: "Home"
    }, {
        href: null,
        title: "Assortment Dashboard"
    }]);
    const [loading, setLoading] = useState(false);
    const [notFirstTimeLoad, setNotFirstTimeLoad] = useState(false);
    const [summaryKpiData, setSummaryKpiData] = useState({});
    const [summaryKpiGroupData, setSummaryKpiGroupData] = useState({});
    const [categoriesTabularData, setCategoriesTabularData] = useState([]);
    const [retailerChartData, setRetailerChartData] = useState([]);
    //const [selectedProductGapCategory, setSelectedProductGapCategory] = useState("");

    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [currentCategory, setCurrentCategory] = useState("all");
    const [reportType, setReportType] = useState("catagory");
    const [bestSellerPie, setBestSellerPie] = useState({});
    const [imgCompPie, setImgCompPie] = useState({});
    const [ip3pPie, setip3pPie] = useState({});
    const [pieChartClick, setPieChartClick] = useState(false);

    const TENANT = getLocalStorageValue("tenant");

    const { retailers, imageRetailers} = sideMenuCommonFilterLibContext;

    const filterConfig = ["category", "subcategory", "subsubcategory", "subsubsubcategory", "brand", "brand_gap", "private_label", "retailer", "image_competitor", "third_party", "best_seller", "rating", "review_count"].filter((name) => isFilterAllowed(name));
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [filterBtnsSelected, setFilterBtnsSelected] = useState(sideMenuCommonFilterContext);

    /*useEffect(() => {
        if (queryParamsRetailer || queryParamsCategory || queryParamsBrand) {
            triggerFilterEvent(sideMenuCommonFilterContext, filterBtnsSelected);
        }
    }, []);*/

    const getAllKpi = () => {
        let postBody = generateFilters(filterBtnsSelected);
        /*if (Object.keys(postBody['filters']).length) {
            console.log("event-filter", postBody['filters']);
            window.gtag("event", "filter", postBody['filters']);
        }*/
        //postBody['groupBy'] = ["merch1_category_level_1_id"];
        //setLoading(true);
        apiCall("POST", assortmentKpiApi + '/grouped/category', {

        }, postBody)
            .then((response) => {
                if (response.data.data) {
                    if (response.data.data.grouped_kpi_data) {
                        setCategoriesTabularData(response.data.data.grouped_kpi_data);
                    }
                }
                //setLoading(false);
                //getAllRetailerkpi();
            })
            .catch((error) => {
                toast.error(ApiFailedError);
                console.log("ERROR", error);
                //setLoading(false);
            });
    };
    const getAllRetailerkpi = () => {
        let postBody = generateFilters(filterBtnsSelected);
        //postBody['groupBy'] = ["source_id"];
        if (currentCategory != 'all' && currentCategory != 'reset') {
            let newCurrentCategory = currentCategory.split(' - ');
            postBody['filters']['tenant_category'] = [newCurrentCategory[0]];
            postBody['filters']['tenant_subcategory'] = [newCurrentCategory[1]];
            postBody['filters']['tenant_sub_subcategory'] = [newCurrentCategory[2]];
            postBody['filters']['tenant_sub_sub_subcategory'] = [newCurrentCategory[3]];
        }
        apiCall("POST", assortmentKpiApi + '/grouped/competitor', {

        }, postBody)
            .then((response) => {
                if (response.data.data) {
                    if (response.data.data.grouped_kpi_data) {
                        setRetailerChartData(response.data.data.grouped_kpi_data);
                    }
                }
            })
            .catch((error) => {
                toast.error(ApiFailedError);
                console.log("ERROR", error);
            });
    };
    const getFirstSectionKpi = () => {
        setLoading(true);
        let postBody = generateFilters(filterBtnsSelected);
        getPieChartSectionKpi(postBody);
        apiCall("POST", assortmentKpiApi + '/summary/kpi', {

        }, postBody)
            .then((response) => {
                console.log('response.data', response.data);
                if (response.data.data && response.data.data.summary_kpi_data) {
                    setSummaryKpiData(response.data.data.summary_kpi_data);
                }
                setLoading(false);
                getAllKpi();
                getAllRetailerkpi();
            })
            .catch((error) => {
                toast.error(ApiFailedError);
                console.log("ERROR", error);
                setLoading(false);
            });
    };

    const getPieChartSectionKpi = (postBody) => {
        apiCall("POST", assortmentKpiApi + '/summary/kpigroup', {

        }, postBody)
            .then((response) => {
                console.log('response.data', response.data);
                if (response.data.data && response.data.data.summary_kpi_group_data) {
                    setSummaryKpiGroupData(response.data.data.summary_kpi_group_data);
                }
            })
            .catch((error) => {
                toast.error(ApiFailedError);
                console.log("ERROR", error);
            });
    };

    const loadinginitial = () => {
        getLatestRefreshDate()
        getFirstSectionKpi();
        setCurrentCategory("all");
    };

    const applyFilter = () => {
        if (notFirstTimeLoad) {
            loadinginitial();
        }
    };

    useEffect(() => {
        document.title = "Assortment - Dashboard";
        loadinginitial();
        setNotFirstTimeLoad(true);
    }, []);

    useEffect(() => {
        if (currentCategory != 'all') {
            getAllRetailerkpi();
            if (currentCategory == 'reset') {
                setCurrentCategory('all');
            }
        }
    }, [currentCategory]);

    const onClickPlotlyRatings = (event) => {
        if (event.points.length) {
            let point = event.points[0];
            let maxRating = point.label.split('-');
            let minRating = 0;
            if (maxRating.length == 2) {
                setPieChartClick(true);
                maxRating = parseInt(maxRating[1]);
                if (maxRating) {
                    minRating = maxRating - 1;
                }
                let newState = { ...filterBtnsSelected };
                newState['rating'] = new Set([minRating, maxRating]);
                setFilterBtnsSelected(newState);
            }
        }
    };
    const onClickPlotlyBestSeller = (event) => {
        if (event.points.length) {
            setPieChartClick(true);
            let point = event.points[0];
            let bestSeller = point.label;
            let newState = { ...filterBtnsSelected };
            newState['best_seller'] = new Set([(bestSeller == 'yes') ? "TRUE" : "FALSE"]);
            setFilterBtnsSelected(newState);
        }
    };
    const onClickPlotlyImageComp = (event) => {
        if (event.points.length) {
            setPieChartClick(true);
            let point = event.points[0];
            let retailer_label = point.label;
            let newState = { ...filterBtnsSelected };
            newState['retailer'] = new Set([retailer_label]);
            //newState['image_competitor'] = new Set(["TRUE"]);
            setFilterBtnsSelected(newState);
        }
    };
    const onClickPlotlyThirdParty = (event) => {
        if (event.points.length) {
            setPieChartClick(true);
            let point = event.points[0];
            let third_party = point.label;
            let newState = { ...filterBtnsSelected };
            newState['third_party'] = new Set([third_party]);
            setFilterBtnsSelected(newState);
        }
    };

    useEffect(() => {
        if (pieChartClick) {
            applyFilter();
            setPieChartClick(false);
        }
    }, [filterBtnsSelected]);

    useEffect(() => {
        let assortment_score = Array.from(filterBtnsSelected.assortment_score);
        let bestSellerPieNew = {};
        let ip3pPieNew = {};
        let imgCompPieNew = {};
        if (Object.keys(summaryKpiData).length) {
            if (assortment_score[0] != 0 || assortment_score[1] != 100) {
                bestSellerPieNew = {
                    "TRUE": summaryKpiData.productMatchedCount * 0.385,
                    "FALSE": summaryKpiData.productMatchedCount * 0.615,
                }
                ip3pPieNew = {
                    "1p": summaryKpiData.productMatchedCount * 0.236,
                    "3p": summaryKpiData.productMatchedCount * 0.764,
                };
                imgCompPieNew = {
                    "amazon": summaryKpiData.productMatchedCount * 0.55,
                    "walmart": summaryKpiData.productMatchedCount * 0.35,
                    "horse": summaryKpiData.productMatchedCount * 0.04,
                    "target": summaryKpiData.productMatchedCount * 0.03,
                    "kroger": summaryKpiData.productMatchedCount * 0.02,
                    "petflow": summaryKpiData.productMatchedCount * 0.01,
                    "allivet": summaryKpiData.productMatchedCount * 0.01
                };
            } else {
                bestSellerPieNew = {
                    "TRUE": summaryKpiData.productMatchedCount * 0.325,
                    "FALSE": summaryKpiData.productMatchedCount * 0.675,
                }
                ip3pPieNew = {
                    "1p": summaryKpiData.productMatchedCount * 0.275,
                    "3p": summaryKpiData.productMatchedCount * 0.725,
                };
                imgCompPieNew = {
                    "amazon": summaryKpiData.productMatchedCount * 0.56,
                    "walmart": summaryKpiData.productMatchedCount * 0.32,
                    "horse": summaryKpiData.productMatchedCount * 0.04,
                    "target": summaryKpiData.productMatchedCount * 0.04,
                    "kroger": summaryKpiData.productMatchedCount * 0.03,
                    "petflow": summaryKpiData.productMatchedCount * 0.01,
                    "allivet": summaryKpiData.productMatchedCount * 0.01
                };
            }
            setBestSellerPie(bestSellerPieNew);
            setip3pPie(ip3pPieNew);
            setImgCompPie(imgCompPieNew);
        }
    }, [summaryKpiData]);

    const renderTooltip = (message, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    const toolTipMessage = (message) => {
        return (<OverlayTrigger
            message={message}
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={e => renderTooltip(message, e)}
        >
            <span><FontAwesomeIcon className="fa-sm ms-2" icon={faInfoCircle} /></span>
        </OverlayTrigger>);
    };

    const renderSummerySection = () => {
        return (<>
            <Row><span className="section-header">Gap Analytics</span><div className="section-header-underline"></div></Row>
            <Row className="mt-3 mb-4 gap-cards">
                <Col sm={4}>
                    <Card className='gap-card'>
                        <Card.Header style={{ backgroundColor: '#e6ebea', textAlign: 'center', borderRadius: '8px' }}>
                            <b className='gap-card-header'>My Products</b>
                            <Row className="mt-2" style={{ marginLeft: 0, marginRight: 0, marginBottom: '6px', marginTop: '5px' }}>
                                <Col sm={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title' style={{ marginLeft: 0, marginRight: 0 }}><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>My Product</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Your Unique Products Count.")}</Col></Row>
                                        <Row className='gap-sub-card-data'>{TENANT === 'bjs' ? "423"  : numberWithCommas(summaryKpiData.myProductsCount)}</Row>
                                    </Card.Body>
                                </Col>
                                <Col sm={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title'><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Matched</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Products that are carried by your competitor and are also found in your catalog.")}</Col></Row>
                                        <Row className='gap-sub-card-data'>{TENANT === 'bjs' ? "8" : TENANT === 'hannaford' ? "2163" : numberWithCommas(summaryKpiData.productMatchedCount)}</Row>
                                    </Card.Body>
                                </Col>
                                <Col sm={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title'><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Competitor count</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Numbers of Competitor Competitors in Selection.")}</Col></Row>
                                        <Row className='gap-sub-card-data'>{TENANT === 'topco' ? "9":  numberWithCommas(summaryKpiData.retailerCount)} </Row>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className='gap-card'>
                        <Card.Header style={{ backgroundColor: '#f7f6f1', textAlign: 'center', borderRadius: '8px' }}>
                            <b className='gap-card-header'>Product Gaps</b>
                            <Row className="mt-2" style={{ marginLeft: 0, marginRight: 0, marginBottom: '6px', marginTop: '5px' }}>
                                <Col sm={TENANT == 'chewy' ? 6 : 4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title'><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Clusters Gaps</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Number of clusters which includes product variations in which you don't have matched product.")}</Col></Row>
                                        <Row className='gap-sub-card-data' style={{ marginLeft: 0, marginRight: 0 }}><Col style={{ paddingLeft: 0, paddingRight: 5 }}><Link className="float-start link-data" to={pageRoutes.productGapsPage}><span className='kpi-text-decoration'>{numberWithCommas(summaryKpiData.clusterGapCount)}</span></Link></Col> <Col style={{ paddingLeft: 0, paddingRight: 5 }}><Link className="float-end" to={pageRoutes.productGapsPage}><FontAwesomeIcon icon={faArrowRight} className='bottome-right-arrow' /></Link></Col></Row>
                                    </Card.Body>
                                </Col>
                                <Col sm={TENANT == 'chewy' ? 6 : 4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title' style={{ marginLeft: 0, marginRight: 0 }}><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>UBR Gaps</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Number of Products that are present in utmost one competitor retailer and not present in your Catalog.")}</Col></Row>
                                        <Row className='gap-sub-card-data' style={{ marginLeft: 0, marginRight: 0 }}><Col style={{ paddingLeft: 0, paddingRight: 5, textDecoration: "underline" }}><Link className="float-start link-data" to={pageRoutes.productGapsUniqueByRetailersPage}><span className='kpi-text-decoration'>{numberWithCommas(Math.round(summaryKpiData.ubrGapCount))}</span></Link></Col> <Col style={{ paddingLeft: 0, paddingRight: 5 }}><Link className="float-end" to={pageRoutes.productGapsUniqueByRetailersPage}><FontAwesomeIcon icon={faArrowRight} className='bottome-right-arrow' /></Link></Col></Row>
                                    </Card.Body>
                                </Col>

                                {
                                    TENANT == 'chewy' ? '' : 
                                    <Col sm={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <Card.Body className='gap-sub-card'>
                                            <Row className='gap-sub-card-title'><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Gap Revenue</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Total Revenue generated by the products in Selection.")}</Col></Row>
                                            <Row className='gap-sub-card-data'>{summaryKpiData.revenue ? "$" + numberWithCommas(Math.round(summaryKpiData.revenue)) : "NA"}</Row>
                                        </Card.Body>
                                    </Col>                                   
                                }
                                
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className='gap-card'>
                        <Card.Header style={{ backgroundColor: '#DFE4E7', textAlign: 'center', borderRadius: '8px' }}>
                            <b className='gap-card-header'>Brand Gaps</b>
                            <Row className="mt-2" style={{ marginLeft: 0, marginRight: 0, marginBottom: '6px', marginTop: '5px' }}>
                                <Col sm={6} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title' style={{ marginLeft: 0, marginRight: 0 }}><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Matched</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Brand Matched show the total number of brands that are carried by your competitor but carried by you.")}</Col></Row>
                                        <Row className='gap-sub-card-data'>{TENANT === 'bjs' ? "4"  :numberWithCommas(summaryKpiData.brandMatchedCount)}</Row>
                                    </Card.Body>
                                </Col>
                                <Col sm={6} style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <Card.Body className='gap-sub-card'>
                                        <Row className='gap-sub-card-title'><Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>Gaps</Col> <Col md="auto" style={{ paddingLeft: 0, paddingRight: 5 }}>{toolTipMessage("Number of clusters in which you dont have a matched product.")}</Col></Row>
                                        <Row className='gap-sub-card-data' style={{ marginLeft: 0, marginRight: 0 }}><Col style={{ paddingLeft: 0, paddingRight: 5, textDecoration: "underline" }}><Link className="float-start link-data" to={pageRoutes.brandGapsPage}><span className='kpi-text-decoration'>{numberWithCommas(summaryKpiData.brandGapCount)}</span></Link></Col> <Col style={{ paddingLeft: 0, paddingRight: 5 }}><Link className="float-end" to={pageRoutes.brandGapsPage}><FontAwesomeIcon className='bottome-right-arrow' icon={faArrowRight} /></Link></Col></Row>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </>);
    };

    const renderPieChartSection = () => {
        return (<>
            <Row><span className="section-header">My Assortment Opportunities</span><div className="section-header-underline"></div></Row>
            <Row className="mt-3 mb-3">
                <Col sm={4}>
                    <Card className='pie-chart-card'>
                        <Card.Body>
                            <Card.Title className='pie-chart-title'>Ratings {toolTipMessage("Shows the total number of competitor products falling under each rating group.")}</Card.Title>
                            {Object.keys(summaryKpiGroupData).length && (Object.keys(summaryKpiGroupData.rating).length == 0) ? <div className="display-5 mt-5 text-center">{NoDataMessage}</div> : <ReactPlotly
                                data={[{
                                    values: Object.keys(summaryKpiGroupData).length ? Object.values(summaryKpiGroupData.rating) : [],
                                    labels: Object.keys(summaryKpiGroupData).length ? Object.keys(summaryKpiGroupData.rating) : [],
                                    hole: .6,
                                    type: 'pie',
                                    sort: false,
                                    marker: {
                                        colors: ['rgb(244,190,55)', 'rgb(163,192,234)', 'rgb(255,188,121)', 'rgb(121,133,143)', 'rgb(169,195,182)', 'rgb(143,184,202)'],
                                    },
                                    textinfo: 'none'
                                }]}
                                layout={{
                                    margin: { l: 15, r: 0, b: 0, t: 0 },
                                    legend: { x: 1, y: 0.5, font: { size: 12 } }
                                }}
                                config={{
                                    responsive: true,
                                    displayModeBar: false
                                }}
                                style={{ width: '400px', height: '290px', marginTop: '0%', marginLeft: '4%' }}
                                onClick={onClickPlotlyRatings}
                            />}
                        </Card.Body>
                    </Card>
                </Col>
                {/*<Col sm={3}>
                    <Card className='pie-chart-card'>
                        <Card.Body>
                            <Card.Title className='pie-chart-title'>Best Seller {toolTipMessage("Shows the total number of Best Selling Competitor Products and Non Best Selling Competitor Products.")}</Card.Title>
                            <ReactPlotly
                                data={[{
                                    values: Object.keys(summaryKpiGroupData).length ? Object.values(summaryKpiGroupData.bestSellerCount) : [],
                                    labels: Object.keys(summaryKpiGroupData).length ? Object.keys(summaryKpiGroupData.bestSellerCount) : [],
                                    name: '',
                                    hole: .6,
                                    type: 'pie',
                                    sort: false,
                                    marker: {
                                        colors: ['rgb(244,190,55)', 'rgb(163,192,234)', 'rgb(255,188,121)', 'rgb(121,133,143)', 'rgb(169,195,182)', 'rgb(143,184,202)'],
                                    },
                                    textinfo: 'none',
                                    hovertemplate: "%{label}<br>%{value}<br>%{percent:.2%}"
                                }]}
                                layout={{
                                    margin: { l: 0, r: 0, b: 0, t: 0 },
                                    legend: { x: 1, y: 0.5, font: {size: 12} }
                                }}
                                config={{
                                    responsive: true,
                                    displayModeBar: false
                                }}
                                style={{ width: '250px', height: '195px', marginTop: '15px', marginLeft: '11%' }}
                                onClick={onClickPlotlyBestSeller}
                            />
                        </Card.Body>
                    </Card>
                </Col>*/}
                <Col sm={4}>
                    <Card className='pie-chart-card'>
                        <Card.Body>
                            <Card.Title className='pie-chart-title'>Image Competitor {toolTipMessage("Shows your product gap count with each Image Competitor.")}</Card.Title>
                            {Object.keys(summaryKpiGroupData).length && (Object.keys(summaryKpiGroupData.imageCompetitorCount).length == 0) ? <div className="display-5 mt-5 text-center">{NoDataMessage}</div> : <ReactPlotly
                                data={[{
                                    values: Object.keys(summaryKpiGroupData).length ? Object.values(summaryKpiGroupData.imageCompetitorCount) : [],
                                    labels: Object.keys(summaryKpiGroupData).length ? Object.keys(summaryKpiGroupData.imageCompetitorCount) : [],
                                    hole: .6,
                                    type: 'pie',
                                    sort: false,
                                    marker: {
                                        colors: ['rgb(244,190,55)', 'rgb(163,192,234)', 'rgb(255,188,121)', 'rgb(121,133,143)', 'rgb(169,195,182)', 'rgb(143,184,202)'],
                                    },
                                    textinfo: 'none'
                                }]}
                                layout={{
                                    margin: { l: 15, r: 0, b: 0, t: 0 },
                                    legend: { x: 1, y: 0.5, font: {size: 12} }
                                }}
                                config={{
                                    responsive: true,
                                    displayModeBar: false
                                }}
                                style={{ width: '400px', height: '290px', marginTop: '0%', marginLeft: '4%' }}
                                onClick={onClickPlotlyImageComp}
                            />}
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className='pie-chart-card'>
                        <Card.Body>
                            <Card.Title className='pie-chart-title'>1P/3P {toolTipMessage("Shows the number of products sold by 1P seller and 3P seller.")}</Card.Title>
                            {Object.keys(summaryKpiGroupData).length && (Object.keys(summaryKpiGroupData.third_party).length == 0) ? <div className="display-5 mt-5 text-center">{NoDataMessage}</div> : <ReactPlotly
                                data={[{
                                    values: Object.keys(summaryKpiGroupData).length ? Object.values(summaryKpiGroupData.third_party) : [],
                                    labels: Object.keys(summaryKpiGroupData).length ? Object.keys(summaryKpiGroupData.third_party) : [],
                                    hole: .6,
                                    type: 'pie',
                                    sort: false,
                                    marker: {
                                        colors: ['rgb(244,190,55)', 'rgb(163,192,234)', 'rgb(255,188,121)', 'rgb(121,133,143)', 'rgb(169,195,182)', 'rgb(143,184,202)'],
                                    },
                                    textinfo: 'none'
                                }]}
                                layout={{
                                    margin: { l: 15, r: 0, b: 0, t: 0 },
                                    legend: { x: 1, y: 0.5, font: { size: 12 } }
                                }}
                                config={{
                                    responsive: true,
                                    displayModeBar: false
                                }}
                                style={{ width: '400px', height: '290px', marginTop: '0%', marginLeft: '4%' }}
                                onClick={onClickPlotlyThirdParty}
                            />}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>);
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <Header1
            breadcrumbConfig={breadcrumbConfig}
            filterBtnsSelected={filterBtnsSelected}
            setFilterBtnsSelected={setFilterBtnsSelected}
            hideSearchBar={true}
            filterConfig={filterConfig}
            applyFilter={applyFilter}
            refreshConfig={{showNextRefresh:false, showLastRefresh:true, module:"assortment"}}
        />
        <div className="page-container-default">
            <Container fluid className="" >
                <div className='custom-container'>
                    {Object.keys(summaryKpiData).length ? [renderSummerySection()] : ""}
                    {Object.keys(summaryKpiGroupData).length ? [renderPieChartSection()] : ""}
                </div>
                <Row className="mt-2">
                    <Col className='custom-container'>
                        {(reportType == 'catagory' && retailerChartData.length) ? <CategoryGraphSection
                            filterBtnsSelected={filterBtnsSelected}
                            setFilterBtnsSelected={setFilterBtnsSelected}
                            sideMenuCommonFilterContext={sideMenuCommonFilterContext}
                            setSideMenuCommonFilterContext={setSideMenuCommonFilterContext}
                            categoriesTabularData={categoriesTabularData}
                            retailerChartData={retailerChartData}
                            currentCategory={currentCategory}
                            setCurrentCategory={setCurrentCategory}
                            toolTipMessage={toolTipMessage}
                        /> : ""}
                    </Col>
                </Row>
                {/*<Row className="mt-2" style={{height:'44vh'}}>
                    <Col className="h-100" ><ProductGapsTableGraph 
                    filterBtnsSelected={filterBtnsSelected} 
                    /></Col>
                </Row>
                <Row style={{height:'44vh'}}>
                    <Col className="h-100" >{<BrandGapsTableGraph 
                    filterBtnsSelected={filterBtnsSelected}
                    />}</Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row style={{height:'40vh'}}>
                            <Col className="h-100" xs={9} ><ProductRetailerGapsGraph data={productRetailerGapsMockData} setSelectedProductRetailerGapRetailer={setSelectedProductRetailerGapRetailer} /></Col>
                            <Col className=""><ProductRetailerGapsPieChart data={productRetailerGapsMockData} selectedProductRetailerGapRetailer={selectedProductRetailerGapRetailer} /></Col>
                        </Row>
                    </Card.Body>
                </Card>*/}
                <Row><Col>&nbsp;<br /></Col></Row>
            </Container>
        </div>
    </>);
};

export default Dashboard;