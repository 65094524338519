import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { getDownloadModalFilterData, apiCall, toolTipMessage } from '../common';
import { UserPersonalizedFilters } from '../constants';
import { toast } from 'react-toastify';
import UserPersonalizedFilterContext from '../../../contextApi/UserPersonalizedFilterContext';
import { contextApiDefaultValueOnClear } from '../Layout/Layout';
import moment from 'moment';

 export const getUserSavedFilters = (setSavedFiltersConfig, setNoOptionsMessage, byPass) => {
  apiCall("GET", UserPersonalizedFilters, {}, null)
    .then((response) => {
      if (response.data.data) {
        const dynamicOptions = response.data.data.map(dataSingle => {
          return {
            label: dataSingle?.filterconfigname,
            value: dataSingle?.id,
            isFavorite: dataSingle?.is_default
          };
        });
        if (!response.data.data.length) {
          setNoOptionsMessage("No saved Filters!");
        }
        setSavedFiltersConfig(prevState => { 
          if (byPass) {
            prevState.byPass = true;
          }
          return {...prevState, 'allSavedFilter':response.data.data, dynamicOptions} 
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      setSavedFiltersConfig([]);
      toast.error("Failed to get Saved Filters!");
    });
};

export const saveUserFilters = (filterConfigName, module, filterBtnsSelected, applyFilter,id, setUserPersonalizedFilterValue, generateFilter, is_favorite) => {
  const postBody = generateFilter(filterBtnsSelected);
  let obj = id ? { id } : {};
  let isFavorite = {"is_default" : is_favorite}
  filterConfigName = filterConfigName.trim();
  apiCall("POST", UserPersonalizedFilters, {}, {...postBody, filterConfigName, module, ...isFavorite,...obj})
    .then((response) => {
      toast.success("Filter Saved Successfully!");
      //setUserPersonalizedFilterValue({ label: filterConfigName, value: response.data.id, allSavedFilter: [] });
      getUserSavedFilters(setUserPersonalizedFilterValue, {}, true);
      applyFilter();
    })
    .catch((error) => {
      console.log("ERROR", error);
      toast.error("Failed to Saved Filter!");
    });
}

export const updateUserFilters = (filterConfigName, module, filterBtnsSelected, applyFilter, id, setUserPersonalizedFilterValue, generateFilter, is_favorite) => {
  const postBody = generateFilter(filterBtnsSelected);
  apiCall("PUT", UserPersonalizedFilters, {}, {...postBody, filterConfigName, module, id, is_favorite})
    .then(() => {
      setUserPersonalizedFilterValue({ label: filterConfigName, value: id, allSavedFilter: [] });
      toast.success("Filter Updated Successfully!");
      applyFilter();
    })
    .catch((error) => {
      console.log("ERROR", error);
      toast.error("Failed to Update Filter!");
    });
}

export const deleteUserFilter = (id, callbackDeleteUserFilter) => {
  apiCall("DELETE", UserPersonalizedFilters, {}, {id})
    .then(() => {
      toast.success("Filter Deleted Successfully!");
      callbackDeleteUserFilter();
    })
    .catch((error) => {
      console.log("ERROR", error);
      toast.error("Failed to Delete Filter!");
    });
};

export const deleteUserFilters = (id, setUserPersonalizedFilterValue, updateData, newFilterSelected) => {
  apiCall("DELETE", UserPersonalizedFilters, {}, {id})
    .then(() => {
      setUserPersonalizedFilterValue({ label: undefined, value: undefined, allSavedFilter: [] });
      toast.success("Filter Deleted Successfully!");
      updateData(newFilterSelected);
    })
    .catch((error) => {
      console.log("ERROR", error);
      toast.error("Failed to Delete Filter!");
    });
}

const SavePersonalizedFilter = (props) => {
  const { show, handleClose, filters, filtersContext, applyFilter, generateFilter, updateData} = props;
  const [UserPersonalizedFilterValue, setUserPersonalizedFilterValue] = useContext(UserPersonalizedFilterContext);
  const [filterNameValue, setFilterNameValue] = useState(undefined);
  const [favoritefilter, setFavoriteFilter] = useState(false);
  const [isInvalidState, setIsInvalidState] = useState(false);
  const newFilterSelected = {};
  Object.keys(filtersContext).map(filterKey => {
    newFilterSelected[filterKey] = contextApiDefaultValueOnClear[filterKey];
  });
  const handleCloseIntercept = () => {
    // First set Name to default value
    setFilterNameValue(undefined);
    setFavoriteFilter(false);
    setIsInvalidState(false);
    // Second Close the modal
    handleClose();
  }

  useEffect(() => {  
    const { allSavedFilter, value } = UserPersonalizedFilterValue;
  
    allSavedFilter.forEach(filter => {
      if (value === filter.id) {
        UserPersonalizedFilterValue.label = filter.filterconfigname;
        UserPersonalizedFilterValue.isFavorite = filter.is_default;
        setFilterNameValue(UserPersonalizedFilterValue.label || `My Filter ${moment().utc().format("YYYYMMDDHHmmss")}`);
        setFavoriteFilter(UserPersonalizedFilterValue.isFavorite);
      }
    });
    if(!value){
      setFilterNameValue(UserPersonalizedFilterValue.label || `My Filter ${moment().utc().format("YYYYMMDDHHmmss")}`);
      setFavoriteFilter(UserPersonalizedFilterValue.isFavorite);
    }
  }, [UserPersonalizedFilterValue]);

  return (
    <Modal show={show} onHide={handleCloseIntercept}>
      <Modal.Header closeButton>
        <Modal.Title><strong>Your Personalized Filters</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label><strong>Enter your Filter Name <span style={{ color: 'red' }}>*</span></strong></Form.Label>
        <InputGroup className="mb-3" hasValidation >
          <Form.Control
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={filterNameValue}
            defaultValue={UserPersonalizedFilterValue?.label || `My Filter ${moment(new Date()).utc().format("YYYYMMDDHHmmss")}`}
            placeholder='Type your filter name'
            onChange={e => { setFilterNameValue(e.target.value);  setIsInvalidState(!e.target.value.length)}}
            required
            isInvalid={isInvalidState}
          />
          <Form.Control.Feedback type="invalid">
            Please provide valid name.
          </Form.Control.Feedback>
        </InputGroup>
        <Row className='g-0'>
          <Col sm={3}>
            <InputGroup className="mb-3">
              <Form.Check type="checkbox" label="Set as Default" checked={favoritefilter} onChange={e => setFavoriteFilter(e.target.checked)} />
            </InputGroup>
          </Col>
          <Col>
            {toolTipMessage('You can choose only One filter as your default.', 'bottom' )}
          </Col>
        </Row>
        
        <div>
          <h5><strong>Filters Applied:</strong></h5>
          <hr className='section-divider' />
          {getDownloadModalFilterData(filters.filter(fitler => {
            return fitler.accessor != 'user';
          }), filtersContext)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* {UserPersonalizedFilterValue.label && <Button variant="danger" type='submit' disabled={isInvalidState} onClick={() => {
          deleteUserFilters(UserPersonalizedFilterValue.value, setUserPersonalizedFilterValue, updateData, newFilterSelected);
          handleCloseIntercept();
        }}>Delete</Button>} */}
        <Button variant="primary" type='submit' disabled={isInvalidState} onClick={() => {
          saveUserFilters(filterNameValue || `My Filter ${moment().utc().format("YYYYMMDDHHmmss")}`, "BOTH", filtersContext, applyFilter, UserPersonalizedFilterValue.value ,setUserPersonalizedFilterValue, generateFilter, favoritefilter);
          handleCloseIntercept();
        }}>Save</Button>
        {/* {UserPersonalizedFilterValue.label && <Button variant="secondary" type='submit' disabled={isInvalidState} onClick={() => {
          updateUserFilters(filterNameValue || UserPersonalizedFilterValue.label, "BOTH", filtersContext, applyFilter, UserPersonalizedFilterValue.value, setUserPersonalizedFilterValue, generateFilter, favoritefilter)
          handleCloseIntercept();
        }}>Save</Button>} */}
        <Button variant="outline-primary" onClick={handleCloseIntercept}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
};

export default SavePersonalizedFilter;