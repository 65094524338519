import React, { useContext, useEffect, useState } from "react";
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import SideMenuCommonFilterLib from "../../../contextApi/SideMenuCommonFilterLib";
import Skeleton from "react-loading-skeleton";
import Select, { StylesConfig, components } from 'react-select';

const FilterLocationWidget = (props) => {

    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        setFilterBtnsSelected,
    } = props;

    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [loading, setLoading] = useState(false);
    const [zipcodeObjs, setZipcodeObjs] = useState({});
    const [allZipcodes, setAllZipcodes] = useState([]);
    const [selectedZipCodes, setSelectedZipCodes] = useState([...filterBtnsSelected.zipcodes]);
    const [isZipCodeMenuOpen, setIsZipCodeMenuOpen] = useState(false);
    const [isZoneMenuOpen, setIsZoneMenuOpen] = useState(false);

    useEffect(() => {
        convertZipcodes();
    }, [sideMenuCommonFilterLibContext]);

    const setFinalValues = () => {
        setFilters();
    }

    const setFilters = () => {
        let newSet = { ...filterBtnsSelected};
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext};
        let tenant_zipcode = sideMenuCommonFilterLibContext.tenant_zipcode.filter(zipcode => {
            return selectedZipCodes.includes(zipcode.zipcode);
        }).map(zipcode => {
            return zipcode.zipcode;
        });
        let comp_zipcode = sideMenuCommonFilterLibContext.comp_zipcode.filter(zipcode => {
            return selectedZipCodes.includes(zipcode.zipcode);
        }).map(zipcode => {
            return zipcode.zipcode;
        });

        newSet['zipcodes'] = selectedZipCodes;
        newSet['tenant_zipcode'] = tenant_zipcode;
        newSet['comp_zipcode'] = comp_zipcode;
        
        newSideMenuCommonFilterContext['zipcodes'] = selectedZipCodes;
        newSideMenuCommonFilterContext['tenant_zipcode'] = tenant_zipcode;
        newSideMenuCommonFilterContext['comp_zipcode'] = comp_zipcode;
        
        setFilterBtnsSelected(newSet);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    };

    const styles: StylesConfig<ColourOption, true> = {
        control: (base, state) => ({
            ...base,
            "border-top": "0",
            "border-left": "0",
            "border-right": "0",
            "border-radius": "0",
            boxShadow: state.isFocused ? 0 : 0,
        }),
        option: (base, {isFocused, isSelected, isMulti}) => ({
            ...base,
            color: isFocused
                ? '#000'
                : isSelected
                    ? '#000'
                    : undefined,
            background: isFocused
                ? 'rgba(0, 0, 0, 0.08)'
                : isSelected
                    ? 'rgba(0, 0, 0, 0.07)'
                    : undefined,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            ":before": {
                content: isMulti 
                    ?  isSelected
                        ? '"☑ "' 
                        : '"☐ "' 
                    : '""'
            }
        }),
        singleValue: (base, state) => ({
            ...base,
            "color": "#316CF4"
        }),
        multiValue: (base, state) => ({
            ...base,
            "color": "#316CF4",
            "background-color": "#fff",
            "border": "1px solid #c0c0c0",
            "border-radius": "12px",
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        //menu: provided => ({ ...provided, zIndex: 9999 }),
        multiValueRemove: (base, state) => ({
            ...base,
            "opacity": (state.data.value == "ALL") ? "0" : "1",
            "width": (state.data.value == "ALL") ? "0" : "auto",
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                "border-top-right-radius": "12px",
                "border-bottom-right-radius": "12px",
            },
        }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            whiteSpace: "normal",
            "color": "#316CF4",
        }),
    };

    const convertZipcodes = () => {
        let contries = [];
        let zones = [];
        let zipcodes = {};
        let allZipcodes = [];
        let allZipcodeValues = [];
        if (sideMenuCommonFilterLibContext.comp_zipcode) {
            allZipcodes = allZipcodes.concat(sideMenuCommonFilterLibContext.comp_zipcode);
        }
        if (sideMenuCommonFilterLibContext.tenant_zipcode) {
            allZipcodes = allZipcodes.concat(sideMenuCommonFilterLibContext.tenant_zipcode);
        }
        
        allZipcodes.map(zipcodeObj => {
            if (!contries.includes(zipcodeObj.country_code)) {
                contries.push(zipcodeObj.country_code);
            }
            let zone = `${zipcodeObj.country_code} - ${zipcodeObj.zone}`; 
            if (!contries.includes(zone)) {
                zones.push(zone);
            }
            if (!(zone in zipcodes)) {
                zipcodes[zone] = [];
            }
            zipcodes[zone].push(zipcodeObj.zipcode);
            allZipcodeValues.push(zipcodeObj.zipcode);
        });
        setZipcodeObjs(zipcodes);
        setAllZipcodes(allZipcodeValues);
    };

    const selectedZonValues = () => {
        let zonValues = [];
        Object.keys(zipcodeObjs).map(zipcodeObj => {
            let objVals = zipcodeObjs[zipcodeObj];
            if (objVals.some(item => selectedZipCodes.includes(item))) {
                zonValues.push({
                    label: zipcodeObj,
                    value: zipcodeObj
                });
            }
        });
        return zonValues;
    };

    const renderZoneSelect = () => {
        return (<Select
            isMulti={true}
            menuIsOpen={isZoneMenuOpen}
            onFocus={e => setIsZoneMenuOpen(true)}
            onBlur={e => {
                setIsZoneMenuOpen(false);
                setFinalValues();
            }}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValue }}
            hideSelectedOptions={false}
            styles={styles}
            value={populateValues(selectedZonValues())}
            isClearable={selectedZonValues().length != 0}
            onChange={items => {
                let zipCodes = [];
                items.filter(item => {
                    return item.value != "ALL";
                }).map(item => {
                    zipCodes = zipCodes.concat(zipcodeObjs[item.value]);
                });
                setSelectedZipCodes(zipCodes);
            }}
            options={Object.keys(zipcodeObjs).sort().map(zipcodeObj => {
                return {
                    label: zipcodeObj,
                    value: zipcodeObj,
                }
            })}
            placeholder="Select A Zone"
            menuPortalTarget={document.body}
        />);
    }

    const renderZipcodeSelect = () => {
        let values = selectedZipCodes.map(selectedZipCode => {
            return {
                label: selectedZipCode,
                value: selectedZipCode
            }
        });
        return (<Select
            isMulti={true}
            menuIsOpen={isZipCodeMenuOpen}
            onFocus={e => setIsZipCodeMenuOpen(true)}
            onBlur={e => {
                setIsZipCodeMenuOpen(false);
                setFinalValues();
            }}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValue }}
            hideSelectedOptions={false}
            styles={styles}
            value={populateValues(values)}
            isClearable={values.length != 0}
            onChange={items => {
                setSelectedZipCodes(items.filter(item => {
                    return item.value != "ALL";
                }).map(item=>item.value))
            }}
            options={allZipcodes.map(allZipcode => {
                return {
                    label: allZipcode,
                    value: allZipcode,
                }
            })}
            placeholder="Select Zipcodes"
            menuPortalTarget={document.body}
        />); 
    };

    const populateValues = (defaultValue) => {
        if (defaultValue.length) {
            return defaultValue;
        } else {
            return [{
                label: "ALL",
                value: "ALL",
                isFixed: true
            }]
        }
    };

    return (<>
        <div
            key={`${ele.name}_zone`}
            title={"Zone"}
            className={
            `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele?.size ? ele?.size : ""} ${ele.name}-zone-label-filter-name` +
            (selected ? "selected" : "") +
            (ele.disabled ? " disabled" : "")
            }
        >
            {<div className="name-top">{"Zone"}</div>}
            {renderZoneSelect()}
        </div>
        <div
            key={`${ele.name}_zipcode`}
            title={"Zipcode"}
            className={
            `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele?.size ? ele?.size : ""} ${ele.name}-zipcode-label-filter-name` +
            (selected ? "selected" : "") +
            (ele.disabled ? " disabled" : "")
            }
        >
            {<div className="name-top">{"Zipcode"}</div>}
            {renderZipcodeSelect()}
        </div>
    </>);
};

export default FilterLocationWidget;

const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        borderRadius: "4px",
        padding: "3px 6px 3px 5px",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        order: 99,
        color: '#316CF4',
        fontWeight: 'bold',
        fontSize: '85%'
    };
  
    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length}`;
  
    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
  
const MultiValue = ({ index, getValue, ...props }) => {
    let stringLimit = 0;
    let maxToShow = 0;
    getValue().map(singleValue => {
        if (stringLimit <= 7) {
            stringLimit += singleValue.value.length;
            maxToShow += 1;
        }
    });
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
  
    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};