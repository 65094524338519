import React, { useState, useEffect, useContext } from "react";
import Select, { StylesConfig, components } from 'react-select';
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import { getLocalStorageValue } from "../../../utils/localStorageOperations";
import { apiCall, columnsMapping } from "../common";
import { UserPersonalizedFilters, filterOptionApi } from "../constants";
import UserPersonalizedFilterContext from "../../../contextApi/UserPersonalizedFilterContext";
import { contextApiDefaultValueOnClear } from "../Layout/Layout";

const SavedFilterSelect = (props) => {
    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        filterBySelectedValues,
    } = props;
    let timer;
    const [UserPersonalizedFilterValue, setUserPersonalizedFilterValue] = useContext(UserPersonalizedFilterContext);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const [dynamicOptions, setDynamicOptions] = useState(UserPersonalizedFilterValue.allSavedFilter.filter(savedFilter => {
        return savedFilter.is_user_filter;
    }).map(savedFilter => {
        let item = {
            label: savedFilter.filterconfigname,
            value: savedFilter.id,
            isDefault: savedFilter?.default_filter,
            data: savedFilter
        };
        return item;
    }));
    const list = dynamicOptions;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dynamicText, setDynamicText] = useState("");
    const [noOptionsMessage, setNoOptionsMessage] = useState(ele.label);
    const [defaultValue, setDefaultValue] = useState(Array.from(filterBtnsSelected[accessor]).map(filterBtnsSelectedSingle => {
        let filterBtnsSelectedSingleLebel;
        list.map(listSingle => {
            if (typeof listSingle['value'] == 'string') {
                if (listSingle['value'] == filterBtnsSelectedSingle) {
                    filterBtnsSelectedSingleLebel = listSingle['label'] ? listSingle['label'] : listSingle[accessor];
                }
            }
        });
        return {
            label: filterBtnsSelectedSingleLebel,
            value: filterBtnsSelectedSingle,
            //isFixed: (Array.from(filterBtnsSelected[accessor]).length == 1) ? true : false
        };
    }));

    const styles: StylesConfig<ColourOption, true> = {
        control: (base, state) => ({
            ...base,
            "border-top": "0",
            "border-left": "0",
            "border-right": "0",
            "border-radius": "0",
            boxShadow: state.isFocused ? 0 : 0,
        }),
        option: (base, {isFocused, isSelected, isMulti}) => ({
            ...base,
            color: isFocused
                ? '#000'
                : isSelected
                    ? '#000'
                    : undefined,
            background: isFocused
                ? 'rgba(0, 0, 0, 0.08)'
                : isSelected
                    ? 'rgba(0, 0, 0, 0.07)'
                    : undefined,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            ":before": {
                content: isMulti 
                    ?  isSelected
                        ? '"☑ "' 
                        : '"☐ "' 
                    : '""'
            }
        }),
        singleValue: (base, state) => ({
            ...base,
            "color": "#316CF4"
        }),
        multiValue: (base, state) => ({
            ...base,
            "color": "#316CF4",
            "background-color": "#fff",
            "border": "1px solid #c0c0c0",
            "border-radius": "12px",
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        //menu: provided => ({ ...provided, zIndex: 9999 }),
        multiValueRemove: (base, state) => ({
            ...base,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                "border-top-right-radius": "12px",
                "border-bottom-right-radius": "12px",
            },
        }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            whiteSpace: "normal",
            "color": "#316CF4",
        }),
        option: (base, {isFocused, isSelected, isMulti}) => ({
            ...base,
            color: isFocused
                ? '#000'
                : isSelected
                    ? '#000'
                    : undefined,
            background: isFocused
                ? 'rgba(0, 0, 0, 0.2)'
                : isSelected
                    ? 'rgba(0, 0, 0, 0.07)'
                    : undefined,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
            ":before": {
                content: isMulti 
                    ?  isSelected
                        ? '"☑ "' 
                        : '"☐ "' 
                    : '""'
            }
        }),
    };

    const onChange = (item) => {
        if (UserPersonalizedFilterValue.byPass) {
            let newUserPersonalizedFilterValue = {...UserPersonalizedFilterValue};
                delete(newUserPersonalizedFilterValue.byPass);
                setUserPersonalizedFilterValue(newUserPersonalizedFilterValue);
        } else {
            let newFitlerSelected = {};
            Object.keys(filterBtnsSelected).map(filterKey => {
                newFitlerSelected[filterKey] = contextApiDefaultValueOnClear[filterKey];
            });
            if (item) {
                let copySet = new Set();
                setDefaultValue(item);
                copySet.add(item.value);
                newFitlerSelected[ele.name] = copySet;
                if (item?.data?.filterconfigvalues) {
                    let savedValues = columnsMapping(item.data.filterconfigvalues);
                    Object.keys(savedValues).map(singleData => {
                        newFitlerSelected[singleData] = new Set(savedValues[singleData]);
                    });
                }
                let newUserPersonalizedFilterValue = {...UserPersonalizedFilterValue};
                newUserPersonalizedFilterValue.label = "";
                newUserPersonalizedFilterValue.value = "";
                newUserPersonalizedFilterValue.isFavorite = false;
                setUserPersonalizedFilterValue(newUserPersonalizedFilterValue);
            }
            filterBySelectedValues(newFitlerSelected);
        }
    };

    useEffect(() => {
        let isDefaultOption = dynamicOptions.find(dynamicOption => dynamicOption.isDefault);
        if (isDefaultOption && defaultValue.length == 0) {
            let newUserPersonalizedFilterValue = {...UserPersonalizedFilterValue};
            newUserPersonalizedFilterValue.allSavedFilter = newUserPersonalizedFilterValue.allSavedFilter.map(savedFilter => {
                savedFilter.default_filter = false;
                return savedFilter;
            });
            setUserPersonalizedFilterValue(newUserPersonalizedFilterValue);
            onChange(isDefaultOption);
        }
    }, [dynamicOptions]);

    const filterOptions = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        return true;
    };

    const renderSelect = () => {
        return (<Select 
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            hideSelectedOptions={false}
            name={accessor}
            isMulti={false}
            isClearable={true}
            styles={styles}
            defaultValue={defaultValue}
            value={defaultValue}
            options={dynamicOptions}
            placeholder={ele.label}
            onChange={onChange}
            noOptionsMessage={() => noOptionsMessage}
            //filterOption={filterOptions}
            menuPortalTarget={document.body}
            isDisabled={ele.isDisabled}
        />);
    };

    return (<div
        key={ele.name}
        title={ele.label}
        className={
          `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele.name}-label-filter-name` +
          (selected ? "selected" : "") +
          (ele.disabled ? " disabled" : "")
        }
      >
        {defaultValue.length ? <div className="name-top">{ele.label}</div> : ""}
        {renderSelect()}
    </div>);
};

export default SavedFilterSelect;