import React from "react";

import "./customInput.scss";

const CustomInput = ({
  type = "text",
  className,
  placeHolder,
  value,
  onChangeHandler,
  onEnterEventHandler,
}) => {
  const onChangeFunc = (e) => {
    if (e.key === "Enter" && onEnterEventHandler instanceof Function) {
      onEnterEventHandler();
    }
    onChangeHandler && onChangeHandler(e.target.value);
  };

  return (
    <input
      type={type}
      className={(className ? className : "") + " custom-input"}
      placeholder={placeHolder}
      value={value}
      onChange={onChangeFunc}
      onKeyPress={onChangeFunc}
    />
  );
};

export default CustomInput;
