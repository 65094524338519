import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell, faArrowsRotate
} from "@fortawesome/free-solid-svg-icons";
import './Notifications.scss';
import { useEffect, useState } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { apiCall } from "../../common/common.js";
import { getLocalStorageValue, setLocalStorageValue } from "../../../utils/localStorageOperations";
import Favicon from "react-favicon";
import { Link, useNavigate } from 'react-router-dom';
import {
    notificationApi
} from "../../common/constants.js";
import Skeleton from "react-loading-skeleton";
import { toast } from 'react-toastify';


export default function Notification(props) {
    const [audio] = useState(new Audio(process.env.PUBLIC_URL + "/audio/notification.mp3"));
    const [dispayContent, setDisplayContent] = useState(false);
    const [notificationContent, setnotificationContent] = useState([]);
    const [notificationIndicator, setNotificationIndicator] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [seen, setSeen] = useState('All');
    const [loading, setLoading] = useState(false);
    const [receivedIds, setReceivedIds] = useState([]);
    const [unreadNotifiationCnt, setUnreadNotifiationCnt] = useState(0);
    const navigate = useNavigate();

    const playNotificationSound = () => {
        audio.play().catch(error => {
            console.error("Error playing audio:", error);
        });
    };

    useEffect(()=>{
        if (receivedIds.length!==0) {
            toast.success('You have received a new notification. Please check your notifications section for more details.')
            playNotificationSound();
            const requestObj = {
                type: 'visited',
                id: receivedIds
            };
            apiCall("PUT", notificationApi, {}, requestObj, false, false, true)
                .then((response) => {
                    console.log(response);
                    setReceivedIds([]);
                })
                .catch((error) => {
                    console.log("ERROR", error);
                });    
        }
    }, [receivedIds])

    const getNotificationData = () => {
        const requestObj = {
            user: getLocalStorageValue("email"),
            type: 'data',
            limit: 5,
            offset: 0,
            order_by: 'created_at',
            order_type: 'desc',
            seen: seen
        }
        setLoading(true);
        apiCall("GET", notificationApi, {}, requestObj, false, false, true)
            .then((response) => {
                const notificationData = response.data.data;
                setUnreadNotifiationCnt(response.data.unreadCnt);
                setnotificationContent(notificationData);
                setLoading(false);
                let unseenNotifications = false;
                let nonReceivedIds = [];
                for(let data of notificationData) {
                    if (data.seen === false) {
                        unseenNotifications = true;
                    }
                    if (data.received === false) {
                        nonReceivedIds.push(data.id);
                    }
                }
                setReceivedIds(nonReceivedIds);
                if (unseenNotifications) {
                    setNotificationIndicator(true);
                }
            })
            .catch((error) => {
                console.log("ERROR", error);
                setLoading(false);
            });
    }

    function notificationClickHandler() {
        setDisplayContent(!dispayContent);
    }

    function notificationRowClickHandler(data) {
        navigate(data.redirect_url);
        setDisplayContent(!dispayContent);
        if (data.seen === false) {
            const requestObj = {
                type: 'seen',
                id: data.id
            };
            setDisplayContent(!dispayContent);
            setLoading(true);
            apiCall("PUT", notificationApi, {}, requestObj, false, false, true)
                .then((response) => {
                    setLoading(false);
                    getNotificationData();
                })
                .catch((error) => {
                    console.log("ERROR", error);
                    setLoading(false);
                });    
        }
    }

    function markAllReadNotifications() {
        const requestObj = {
            type: 'readAll',
            email: getLocalStorageValue("email")
        };
        setDisplayContent(!dispayContent);
        setLoading(true);
        apiCall("PUT", notificationApi, {}, requestObj, false, false, true)
            .then((response) => {
                setLoading(false);
                getNotificationData();
            })
            .catch((error) => {
                console.log("ERROR", error);
                setLoading(false);
            });    
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!dispayContent && e.target.closest('.notification') === null) {
                setDisplayContent(false);
            }
        };
        document.addEventListener("click", handleClickOutside);

        getNotificationData();
        const livePriceIntervalId = setInterval(() => {
            getNotificationData();
        }, 60000)

        return () => {
            clearInterval(livePriceIntervalId);
        };
    }, [seen])

    function notificationClicked(data) {
        setDisplayContent(false);
    }

    const loadFavicon = () => {
        if (unreadNotifiationCnt > 0) {
            return (<Favicon iconSize={16} animated={true} alertFillColor="blue" alertCount={unreadNotifiationCnt} url={process.env.PUBLIC_URL + "/favicon.ico"} />)
        } else {
            return (<Favicon url={process.env.PUBLIC_URL + "/favicon.ico"} />)
        }
    }

    return (
        <div className="notification">
            {loadFavicon()}
            <div className='notification-header' onClick={notificationClickHandler}>
                <FontAwesomeIcon className='bell-icon' icon={faBell} />
                {unreadNotifiationCnt>0 && <div className='notification-indicater'>{unreadNotifiationCnt}</div>}
            </div>
            {dispayContent && <div className='notification-content'>
                <div className="notch"></div>
                <div className='notification-heading'>
                    <Row>
                        <Col className='mt-2' sm={4}>
                            Notifcations
                        </Col>
                        <Col className='float-end'>
                            <button className={seen === 'All' ? 'notification-type-button button-selected' : 'notification-type-button'} onClick={() => { setSeen('All') }} variant="light">All</button>
                            <button className={seen === 'Unread' ? 'notification-type-button button-selected' : 'notification-type-button'} onClick={() => { setSeen('Unread') }} style={{ marginLeft: '10px' }} variant="light">Unread</button>
                            <Button onClick={()=>{markAllReadNotifications()}} style={{ marginLeft: '10px' }} variant="light">Mark all as read</Button>
                        </Col>
                    </Row>
                </div>
                {loading === false ? <div className='notification-body'>
                    <div className='notification-body-content'>
                        {notificationContent.length !== 0 ?
                            notificationContent.map((data) => {
                                return (<Row style={data.seen === false ? { backgroundColor: '#f5f4ec', padding: '10px 5px', margin: '0px 0px' } : { padding: '10px 5px', margin: '0px 0px', minHeight: '50px', verticalAlign: 'middle', cursor: 'pointer' }} className='notification-body-row' onClick={()=>{notificationRowClickHandler(data)}}>
                                    <Col sm={1} style={{ display: 'flex', alignItems: 'center', height: "40px", width: "40px", textAlign: 'center', fontSize: "16px", marginRight: '10px' }}>
                                        {data.notification_type === 'Price Data' ? <img width="40px" height="40px" src={process.env.PUBLIC_URL + "/images/icons/common/data-refresh.svg"} />: 
                                        data.notification_type === 'Rules' ? <img width="40px" height="40px" src={process.env.PUBLIC_URL + "/images/icons/common/rule.svg"} /> :  <img width="40px" height="40px" src={process.env.PUBLIC_URL + "/images/icons/common/data-refresh.svg"} />}
                                    </Col>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        {data.title}
                                    </Col>
                                </Row>)
                            })
                            :
                            <Row className='notification-data mt-2'>
                                <Col className='mt-2 no-notification-yet'>
                                    No notifications <img width="25px" src={process.env.PUBLIC_URL + "/images/no-notification-yet.gif"} />
                                </Col>
                            </Row>
                        }
                    </div>
                </div> : <Skeleton width="100" height="250px" />}
                <div className='notification-footer'>
                    <Link to="/notifications" onClick={()=>{setDisplayContent(!dispayContent)}}>View all notifications</Link>
                </div>
            </div>}
        </div>
    )
}