/**
 * @description API endpoints
 * @example Steps to add a API endpoint
 * Step 1. `export const SAMPLE_URL = 'BASE_URL/endpoint'`
 * Step 2. Do not forget to add a inline comment on the top of the URL declaration
 */

export const isDemoEnv = (localStorage.getItem('currentEnv') == "demo");

//export const retailscapeUrl =   (process.env.REACT_APP_NODE_ENV === "prod") ? prodRetailscapeEndPoint : (process.env.REACT_APP_NODE_ENV === "demo") ? demoRetailscapeEndPoint : devRetailscapeEndPoint;

export const loginApi = "/auth/login";
export const signUpApi = "/auth/signup";
export const verifyEmailApi = "/auth/verify";
export const passResetApi = "/auth/password/reset";
export const confirmPassResetApi = "/auth/password/confirm";
export const refreshAuthTokenApi = "/auth/refreshToken";
export const filterOptionApi = "/tenant/filter/autocomplete";
export const tenantInfoApi = "/tenant";
export const tenantAllEmail = "/tenant/users"
export const latestRefreshDates = "/tenant/latestRefreshDates"
export const passThroughApi = "/auth/passThrough"

//Assortment apis
export const dashProductGapsAnalytics = "/pg";
export const dashBrandGapsAnalytics = "/bg";
export const filtersApi = "/tenant/filter";
export const assortmentFiltersApi = "/assortmentFilter";
export const pricingFilterApi = "/pricingFilter";
export const assortmentKpiApi = "/assortmentKPI";
export const assortmentPieBrandList = "/brands";
export const assortmentKpiDetailApi = "/assortmentKPIDetail";
export const suppressedKpiDetailApi = "/suppressionListing";
export const clusterProductApi = "/clusterProducts";
export const assortmentPricingLeadertApi = "/assortment/priceLeaderLagger/category";
export const assortmentPricingLeadertBrandApi = "/assortment/priceLeaderLagger/brand";
export const assortmentSuppressionCode = "/actioning/code";
export const assortmentSuppressionAction = "/actioning";
export const assortmentCategoryMappingAction = "/categoryMapping";
export const getCategoryMappingInfo = "/editCategoryInfo";
export const bulkActionUpload = "/bulk-action";
//Pricing apis
export const pricingKpiApi = "/pricing/kpi";
export const pricingCatelogCpiApi = "/pricing/catalog/cpi";
export const pricingCatelogApi = "/pricing/catalog";
export const pricingCompetitorApi = "/pricing/competitor";
export const pricingWeeklyKpiApi = "/pricing/weekly";
export const pricingDailyKpiApi = "/pricing/daily";
export const pricingMonthKpiApi = "/pricing/monthly";
export const pricingProductsApi = "/matchedProducts";
export const pricingCompetitorProductsApi = "/competitorProducts";
export const pricingCompetitorProductLandscapeApi = "/competitorProductLandscape";
export const pricingProductLandscapeApi = "/Product/CompetitiveLandscape";
export const priceChangeHistoryApi = "/suggestion/trail";
export const priceChangeHistoryApiDownload = "/suggestion/trail/download";
export const priceSuggetionApi = "/suggestion";
export const priceApprovalApi = "/approval";
export const pricingProductLandscapeTimeSeriesApi = "/Product/CompetitorTimeSeries";
export const pricingProductLandscapeSummaryApi = "/Product/CompetitorSummary";
export const pricingricingLeadertApi = "/pricing/priceLeaderLagger/category";
export const pricingricingLeadertBrandApi = "/pricing/priceLeaderLagger/brand";
export const instoreDataApi = "/Product/InstoreProducts";
export const salesTrendApi = "/sales";
export const weightedCPITrendApi = "/weightedCPI";
export const livePriceCheckApi = "/pricing/livePriceCheck";
export const notification = '/notifications';
export const priceSuppressionApi = '/pricing/suppression'
export const priceGroupApi = '/priceGroup';
export const pricingCpiMapView = '/pricing/cpiMapView';
export const pricingCpiMapList = "/pricing/cpiMapList";

//Sales apis
export const salesApi = "/sales";

//weightedCPI/listing
export const weightedCpiApi = "/weightedCPI";

//Other apis
//export const reportAnIssue = "https://ytcxlks2cd.execute-api.us-east-1.amazonaws.com/create-report";
export const reportAnIssue = "https://ytcxlks2cd.execute-api.us-east-1.amazonaws.com/create-report-v2";

// Download apis
export const brandGapDataDownload = "/assortment/download/brandGap";
export const productGapDataDownload = "/assortment/download/productGap";
export const ubrGapDataDownload = "/assortment/download/ubrGap";
export const pricingProductsDownload = "/pricing/download/matchedProducts";
export const pricingProductsRulesDownload = "/suggestion/download/listingProducts";
export const pricingProductsSalesDownload = "/sales/download/listingProducts";

// Personalised Filters Api
export const UserPersonalizedFilters = "/user/personalization/filter";

// Favorites Product and Brand
export const GetFavoriteProduct = "/user/personalization/favorite/productList";
export const GetFavoriteBrand = "/user/personalization/favorite/brandList";
export const FavoriteProduct = "/user/personalization/favorite/product";
export const FavoriteBrand = "/user/personalization/favorite/brand";

// Pricing Rules Api
export const rulesApi = "/rules";
export const rulesSuggationSummaryApi = "/rules/suggestion/summary";
export const rulesPriorityApi = "/rulespriority";
export const rulesGroupApi = "/rulesgroup";
export const systemRulesApi = "/systemrules";

// Feature Name
export const UserPersonalizedFilterFeatureName = "user_personalized_filter";
export const Suppression = "suppression";
export const EditCategoryFeature = "edit";
export const DownloadFeature = "download";
export const Watchlist = "favorite";

// Features List
export const SAVED_FILTER = "user_personalized_filter";
export const SUPPRESSION = "suppression";
export const BULK_ACTION = "bulk_upload_action"
export const EDIT = "edit";
export const DOWNLOAD = "download";
export const WATCHLIST = "favorite";
export const PRODUCT_GAP = "product_gap";
export const BRAND_GAP = "brand_gap";
export const PRICE_LEADER = "price_leader";
export const SUGGESTIONS = "Suggestions";
export const PROMOPRICE_CPI = 'promo_price_cpi'
export const ZIPCODES = "zipcode";
export const ZONES = "zone";
export const LIVEPRICE = "live_price_check";
export const SALES = "Sales";
export const WEIGHTED_CPI = "Weighted_CPI";
export const REGULAR_PRICE_CPI = "regular_price_cpi";
export const CUSTOM_PRICE_CPI = "custom_price_cpi";
export const KEY_COMP = "key_competitor";
export const HISTORICAL_PRICING = "historical_pricing";
export const MATCH_COVERAGE = "match_coverage";
export const MATCH_OPPORTUNITIES = "match_opportunities";
export const TPVR_REPORT = "tpvr_report";
export const PRICE_GROUP = "price_group";
export const EXECUTIVE_SUMMARY = "executive_summary";

// Reports List
export const reportsApi = "/reports";
export const APPROVAL_SUMMARY = 'approval_summary';
export const OFFLINE_FEEDS = 'offline_feeds';
export const PRICING_FEEDS = 'pricing_feeds';
export const OUTLIER_REPORT = 'outlier_report';
export const SUGGESTION_REPORT = '/reports/suggestion';
export const PRODUCT_SUGGESTION_REPORT = '/reports/product-suggestion';
export const reportsMatchCoverageApi = '/match/coverage';
export const reportsMatchApi = "/match";
export const tpvrKpiApi = '/reports/tpvr/kpi';
export const metricsApi = '/reports/tpvr/metrics';
export const summaryApi = '/reports/tpvr/summary';
export const workFlowApi = '/reports/tpvr/workflows';
export const downloadIndepthReportApi = '/reports/tpvr/in-depth-report-download';
export const notificationApi = '/notification/inAppNotifications';
export const priceComparisionListingApi = '/pricing/priceComparisonListing';
export const priceComparisionApi = '/pricing/priceComparison';

//Generic Message
export const FeatureNotEnabled = 'Sorry, this feature is currently not enabled for your account. Please reach out to your administrator for assistance.';
export const ApiFailedError = 'Something went wrong. Please try again later.';
export const NoDataMessage = 'No data available at the moment. Please try again later.';

export const brandCount = 50;
export const brandAutoCompleteList = [ "AssortmentBrand" ,"PricingBrand"];

// Heat Map constants
export const mapBoxApiKey = "pk.eyJ1IjoibmF2aW5iYW1hbmUiLCJhIjoiY2t3YnBxNm9oMDMxaTMxdDN4cGlwOG04YyJ9.pjw_yivWo9XQWUC842xbDA";
export const centerCoorDefaultForUSA = {
  center: {
    //lat: 48.935186, //37.335917, //for us
    //lng: -110.656768, //-95.572065, //for us
    lat: 37.335917, //for us
    lng: -95.572065,
  },
};