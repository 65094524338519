import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import CategoryGraphMockdata from './categoryGraphMockData';
import Skeleton from "react-loading-skeleton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLink, faSort, faSortAlphaDownAlt, faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import * as pageRoutes from "../../pageRoutes";
import { apiCall, getCustomLabel, isColumnAllowed, numberWithCommas } from "../common/common.js";
import CategoryGraphBarChart from "./CategoryGraphBarChart";
import CategoryGraphPieChart from "./CategoryGraphPieChart";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import BrandGapsGraphBarChart from "./BrandGapsGraphBarChart";
import CategoryGraphBarChart1 from "./CategoryGraphBarChart1";
import BrandGapsGraphBarChart1 from "./BrandGapsGraphBarChart1";
import CategoryGraphBarChart2 from "./CategoryGraphBarChart2";
import CategoryGraphBarChart3 from "./CategoryGraphBarChart3";
import BrandGapsGraphBarChart2 from "./BrandGapsGraphBarChart12";
import UbrGapsGraphBarChart from "./UbrGapsGraphBarChart";
import ClusterGapsGraphBarChart from "./ClusterGapsGraphBarChart";
import { NoDataMessage } from "../common/constants";


const CategoryGraphSection = (props) => {
    const {
        filterBtnsSelected,
        setFilterBtnsSelected,
        sideMenuCommonFilterContext,
        setSideMenuCommonFilterContext,
        categoriesTabularData,
        retailerChartData,
        currentCategory,
        setCurrentCategory,
        toolTipMessage,
    } = props;
    const navigate = useNavigate();
    const divRef = useRef(null);
    const [plotlyData, setPlotlyData] = useState({});
    const [currentRetailer, setCurrentRetailer] = useState("all");
    const [dataResp, setDataResp] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("Ascending|category");
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);

    const endcodString = (string) => {
        let endcString = string.replace("&", "%26");
        return endcString;
    };

    function compare( a, b ) {
        if ( a.category < b.category ){
          return -1;
        }
        if ( a.category > b.category ){
          return 1;
        }
        return 0;
      }

    const renderCategoryGaps = () => {
        if (Object.keys(plotlyData).length >= 2) {
            let sortTableRecs = [];
            let sortArray = sort.split("|");
            Object.keys(plotlyData).map(pltolyDataSingle => {
                let newObject = plotlyData[pltolyDataSingle];
                newObject['category'] = pltolyDataSingle;
                let categories = pltolyDataSingle.split(' - ');
                if (categories.length != 1) {
                    sortTableRecs.push(newObject);
                }
            });
            if (sortArray[1] == 'category') {
                if (sortArray[0] == 'Ascending') {
                    sortTableRecs = sortTableRecs.sort(compare);
                } else {
                    sortTableRecs = sortTableRecs.reverse();
                }
            }
            return sortTableRecs.sort((a, b) => {
                if (sortArray[0] == 'Ascending') {
                    return a[sortArray[1]] - b[sortArray[1]];
                } else {
                    return b[sortArray[1]] - a[sortArray[1]];
                }
            }).map(pltolyDataSingleRec => {
                let tableClass = (pltolyDataSingleRec['category'] == currentCategory) ? "table-active" : "";
                let categories = pltolyDataSingleRec['category'].split(' - ');
                return (<tr key={Math.random()} className={tableClass} onClick={() => [setCurrentCategory(pltolyDataSingleRec['category']), setCurrentRetailer('all')]}>
                    <td >{categories[0] + " - " + categories[1] + " - " + categories[2] + (isColumnAllowed("Sub Sub Sub Category") ? ` - ${categories[3]}` : '')}</td>
                    <td>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.brandGapsPage} className="dashboard-link-img"> </Link>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.brandGapsPage}>
                            {numberWithCommas(pltolyDataSingleRec['brandGapCount'])}
                        </Link>
                    </td>
                    <td>{numberWithCommas(pltolyDataSingleRec['productGapCount'])}</td>
                    <td>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.productGapsPage} className="dashboard-link-img"> </Link>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.productGapsPage}>
                            {numberWithCommas(pltolyDataSingleRec['clusterGapCount'])}
                        </Link>
                    </td>
                    <td>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.productGapsUniqueByRetailersPage} className="dashboard-link-img"> </Link>
                        <Link onClick={e => setCategories(categories)} to={pageRoutes.productGapsUniqueByRetailersPage}>
                            {numberWithCommas(pltolyDataSingleRec['ubrGapCount'])}
                        </Link>
                    </td>
                    <td>{numberWithCommas(pltolyDataSingleRec['bestSellerCount'])}</td>
                </tr>);
            });
        }
    };

    const setCategories = (categories) => {
        let newFilterBtnsSelected = { ...filterBtnsSelected };
        let newSideMenuCommonFilterContext = { ...sideMenuCommonFilterContext };
        newFilterBtnsSelected.category = newSideMenuCommonFilterContext.category = new Set([categories[0]]);
        newFilterBtnsSelected.subcategory = newSideMenuCommonFilterContext.subcategory = new Set([categories[1]]);
        newFilterBtnsSelected.subsubcategory = newSideMenuCommonFilterContext.subsubcategory = new Set([categories[2]]);
        newFilterBtnsSelected.subsubsubcategory = newSideMenuCommonFilterContext.subsubsubcategory = new Set([categories[3]]);
        setFilterBtnsSelected(newFilterBtnsSelected);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    };

    const getGaps = () => {
        //console.log('CategoryGraphMockdata', CategoryGraphMockdata);
        setDataResp(categoriesTabularData);
    };

    /*useEffect(() => {
        getGaps();
    }, [getGaps]);*/

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.category1Lib && categoriesTabularData.length) {
            getGaps();
        }
    }, [categoriesTabularData, sideMenuCommonFilterLibContext, filterBtnsSelected]);

    useEffect(() => {
        if (currentCategory != 'all') {
            divRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentCategory])

    useEffect(() => {
        let categoryGroup = {
            'all': {
                'brandGapCount': 0,
                'brandMatchedCount': 0,
                'clusterGapCount': 0,
                'clusterMatchedCount': 0,
                'productGapCount': 0,
                'productMatchedCount': 0,
                'ubrGapCount': 0,
                'bestSellerCount': 0,
            }
        };
        let category1Lib = sideMenuCommonFilterLibContext.category1Lib;
        let subcategory1Lib = sideMenuCommonFilterLibContext.subcategory1Lib;
        let subsubcategory1Lib = sideMenuCommonFilterLibContext.subsubcategory1Lib;
        let subsubsubcategory1Lib = sideMenuCommonFilterLibContext.subsubsubcategory1Lib;
        dataResp.map(dataSingle => {
            if (dataSingle.tenant_category_id in category1Lib) {
                const category = category1Lib[dataSingle.tenant_category_id];
                const subcategory = subcategory1Lib[dataSingle.tenant_subcategory_id];
                const subsubcategory = subsubcategory1Lib[dataSingle.tenant_sub_subcategory_id];
                const subsubsubcategory = subsubsubcategory1Lib[dataSingle.tenant_sub_sub_subcategory_id];
                const label = category + ' - ' + subcategory + ' - ' + subsubcategory + (isColumnAllowed("Sub Sub Sub Category") ? ` - ${subsubsubcategory}` : '');
                if (!(label in categoryGroup)) {
                    categoryGroup[label] = {
                        'brandGapCount': 0,
                        'brandMatchedCount': 0,
                        'clusterGapCount': 0,
                        'clusterMatchedCount': 0,
                        'productGapCount': 0,
                        'productMatchedCount': 0,
                        'ubrGapCount': 0,
                        'bestSellerCount': 0,
                    };
                }

                categoryGroup[label]['brandGapCount'] += dataSingle.brandGapCount;
                categoryGroup[label]['brandMatchedCount'] += dataSingle.brandMatchedCount;
                categoryGroup[label]['clusterGapCount'] += dataSingle.clusterGapCount;
                categoryGroup[label]['clusterMatchedCount'] += dataSingle.clusterMatchedCount;
                categoryGroup[label]['productGapCount'] += dataSingle.productGapCount;
                categoryGroup[label]['productMatchedCount'] += dataSingle.productMatchedCount;
                categoryGroup[label]['ubrGapCount'] += dataSingle.ubrGapCount;
                categoryGroup[label]['bestSellerCount'] += dataSingle.bestSellerCount;

                categoryGroup['all']['brandGapCount'] += dataSingle.brandGapCount;
                categoryGroup['all']['brandMatchedCount'] += dataSingle.brandMatchedCount;
                categoryGroup['all']['clusterGapCount'] += dataSingle.clusterGapCount;
                categoryGroup['all']['clusterMatchedCount'] += dataSingle.clusterMatchedCount;
                categoryGroup['all']['productGapCount'] += dataSingle.productGapCount;
                categoryGroup['all']['productMatchedCount'] += dataSingle.productMatchedCount;
                categoryGroup['all']['ubrGapCount'] += dataSingle.ubrGapCount;
                categoryGroup['all']['bestSellerCount'] += dataSingle.bestSellerCount;
            }
        });
        setPlotlyData(categoryGroup);
    }, [dataResp]);

    const rowLabel = (row) => {
        let sortArray = sort.split("|");
        return <a
            onClick={e => {
                if (sortArray[1] === row.value) {
                    if (sortArray[0] === 'Ascending') {
                        setSort("Descending|" + row.value);
                    } else {
                        setSort("Ascending|" + row.value);
                    }
                } else {
                    setSort("Ascending|" + row.value);
                }
            }}
            className="btn btn-link text-decoration-none">
            {row.label}<FontAwesomeIcon
                className={(sortArray[1] === row.value) ? "ms-4" : "opacity-25 ms-4"}
                icon={(sortArray[1] === row.value) ? (sortArray[0] === 'Ascending' ? faSortAlphaUp : faSortAlphaDownAlt) : (faSort)}
            />
        </a>;
    };



    return (
        <>
        <Row className="mt-3"><span className="section-header">My Assortment Opportunities</span><div className="section-header-underline"></div></Row>
        {
            (Object.keys(plotlyData).length >= 2) ? <Row className='mt-3' style={{ height: '44vh' }} ref={divRef}>
            <Col>
            <div className="product-gaps-dash-table-custom-scrollbar">
                <Table className="retailscape-table">
                    <thead>
                        <tr>
                            <th>{rowLabel({
                                "label": ['Category', 'Sub Category', 'Sub Sub Category', 'Sub Sub Sub Category'].filter(item => isColumnAllowed(item)).map(item => getCustomLabel(item)).join(' - '),
                                "value": "category"
                            })}</th>
                            <th>{rowLabel({
                                "label": "Unique Brand Gaps",
                                "value": "brandGapCount"
                            })}</th>
                            <th>{rowLabel({
                                "label": "Product gaps",
                                "value": "productGapCount"
                            })}</th>
                            <th>{rowLabel({
                                "label": "Cluster product gaps",
                                "value": "clusterGapCount"
                            })}</th>
                            <th>{rowLabel({
                                "label": "UBR gaps",
                                "value": "ubrGapCount"
                            })}</th>
                            <th>{rowLabel({
                                "label": "Best Sellers",
                                "value": "bestSellerCount"
                            })}</th>
                        </tr>
                    </thead>
                    <tbody>{loading ? <tr><td colSpan={3}><Skeleton width="100" height="35vh" /></td></tr> : renderCategoryGaps()}</tbody>
                </Table>
            </div>
            </Col>
        </Row> : <div className="display-5 mt-5 text-center">{NoDataMessage}</div>
        }
            {/*<Row style={{height:'44vh'}}>
                <Col xs={12}>
                    <CategoryGraphBarChart data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
            </Row>
            <hr className="mt-50"/>*/}
            <Row className="mt-3 mb-4">
                <span className="section-header">My Assortment {(currentCategory === "all") ? 'Cluster Gaps' : ('(' + currentCategory + ') Cluster Gaps')}</span>
                <div className="section-header-underline"></div>
            </Row>
            <Row style={{ height: '44vh' }}>
                <Col xs={12}>
                    <ClusterGapsGraphBarChart
                        filterBtnsSelected={filterBtnsSelected}
                        setFilterBtnsSelected={setFilterBtnsSelected}
                        sideMenuCommonFilterContext={sideMenuCommonFilterContext}
                        setSideMenuCommonFilterContext={setSideMenuCommonFilterContext}
                        data={retailerChartData}
                        currentCategory={currentCategory}
                        setCurrentRetailer={setCurrentRetailer}
                    />
                </Col>
            </Row>
            <Row className="mt-3 mb-4"><span className="section-header">My Assortment {(currentCategory === "all") ? 'UBR Gaps' : ('(' + currentCategory + ') UBR Gaps')}</span><div className="section-header-underline"></div></Row>
            <Row style={{ height: '44vh' }} >
                <Col xs={12}>
                    <UbrGapsGraphBarChart
                        filterBtnsSelected={filterBtnsSelected}
                        setFilterBtnsSelected={setFilterBtnsSelected}
                        sideMenuCommonFilterContext={sideMenuCommonFilterContext}
                        setSideMenuCommonFilterContext={setSideMenuCommonFilterContext}
                        data={retailerChartData}
                        currentCategory={currentCategory}
                        setCurrentRetailer={setCurrentRetailer}
                    />
                </Col>
            </Row>
            <Row className="mt-3 mb-4"><span className="section-header">My Assortment {(currentCategory === "all") ? 'Brand Gaps' : ('(' + currentCategory + ') Brand Gaps')}</span><div className="section-header-underline"></div></Row>
            <Row style={{ height: '44vh' }}>
                <Col xs={12}>
                    <BrandGapsGraphBarChart
                        filterBtnsSelected={filterBtnsSelected}
                        setFilterBtnsSelected={setFilterBtnsSelected}
                        sideMenuCommonFilterContext={sideMenuCommonFilterContext}
                        setSideMenuCommonFilterContext={setSideMenuCommonFilterContext}
                        data={retailerChartData}
                        currentCategory={currentCategory}
                        setCurrentRetailer={setCurrentRetailer}
                    />
                </Col>
            </Row>
            {/*<hr className="mt-50"/>
            <Row style={{height:'800px'}}>
                <Col xs={6}>
                    <CategoryGraphBarChart1 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
                <Col xs={6}>
                    <BrandGapsGraphBarChart1 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
            </Row>
            <hr className="mt-50"/>
            <Row style={{height:'800px'}}>
                <Col xs={6}>
                    <CategoryGraphBarChart2 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
                <Col xs={6}>
                    <BrandGapsGraphBarChart2 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
            </Row>
            <hr className="mt-50"/>
            <Row style={{height:'800px'}}>
                <Col xs={12}>
                    <CategoryGraphBarChart2 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
            </Row>
            <hr className="mt-50"/>
            <Row style={{height:'44vh'}} ref={divRef}>
                <Col xs={12}>
                    <CategoryGraphBarChart3 data={retailerChartData} currentCategory={currentCategory} setCurrentRetailer={setCurrentRetailer} />
                </Col>
    </Row>*/}
        </>
    );
};

export default CategoryGraphSection;