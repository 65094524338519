import React from "react";
import CustomInput from "../CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCross,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import "./searchBar.scss";

const SearchBar = ({
  searchValue,
  setSearchValue,
  onClickEventHandler = () => {},
  placeHolder = "Search by keywords",
  searchIconleft = false,
  className,
  searchSubmitActionOn,
}) => {
  const clearSearchHandler = () => {
    setSearchValue("");
    onClickEventHandler("");
  };
  const searchIcon = searchValue ? (<button className="btn btn-primary" onClick={clearSearchHandler}><FontAwesomeIcon className="fa-sm" icon={faCross} /></button> ) : (
    <button className="btn btn-primary" onClick={onClickEventHandler}>
      <FontAwesomeIcon className="fa-sm" icon={faSearch} />
    </button>
  );

  if (onClickEventHandler) {
    return (
      <>
        <div
          className={
            "search-container disp-flex align-center " +
            (className ? className : "")
          }
        >
          {searchIconleft && searchIcon}
          <CustomInput
            type="text"
            placeHolder={placeHolder}
            value={searchValue}
            onChangeHandler={setSearchValue}
            onEnterEventHandler={onClickEventHandler}
          />
          {!searchIconleft && !searchSubmitActionOn && searchIcon}
          {searchSubmitActionOn && (
            <button className="btn btn-primary" onClick={onClickEventHandler}>
              {searchSubmitActionOn}
            </button>
          )}
        </div>
      </>
    );
  }

  return "";
};

export default SearchBar;
