import React, { useState } from "react";
import { toast } from 'react-toastify';
import CreateableSelect from 'react-select/creatable';

const FilterMinMax = (props) => {
  const {
    ele,
    filterBtnsSelected,
    openFilterLayout,
    filterBySelectedValues,
  } = props;
  const accessor = ele.accessor;
  const selected = openFilterLayout === ele.name;
  const [minValue, maxValue] = Array.from(filterBtnsSelected[accessor]);
  const [defaultMinValue, setDefaultMinValue] = useState({ label: minValue, value: minValue });
  const [defaultMaxValue, setDefaultMaxValue] = useState({ label: maxValue, value: maxValue });

  const onChangeForMin = (selectedOption) => {
    if (!selectedOption) {
      selectedOption = { label: ele.min, value: ele.min };
    }

    if (+selectedOption.value < +defaultMaxValue.value) {
      setDefaultMinValue(selectedOption);
      const copySet = new Set([+selectedOption.value, +defaultMaxValue.value]);
      filterBySelectedValues({ [ele.name]: copySet });
    }
    else {
      toast.warn("Min-count should be less than Max-count.");
      setDefaultMinValue(prevValue => prevValue);
    }
  };

  const onChangeForMax = (selectedOption) => {
    if (!selectedOption) {
      selectedOption = { label: ele.max, value: ele.max };
    }

    if (+selectedOption.value > +defaultMinValue.value) {
      setDefaultMaxValue(selectedOption);
      const copySet = new Set([+defaultMinValue.value, +selectedOption.value]);
      filterBySelectedValues({ [ele.name]: copySet });
    }
    else {
      toast.warn("Max-count should be greater than Min-count.");
      setDefaultMinValue(prevValue => prevValue);
    }
  };

  const styles = {
    control: (css, state) => ({
      ...css,
      boxShadow: state.isFocused ? 0 : 0,
      width: '60px',
      height: '35px',
      "border-top": "0",
      "border-left": "0",
      "border-right": "0",
      "border-radius": "0",
    }),
    singleValue: (base, state) => ({
      ...base,
      "color": "#316CF4"
    }),
    option: (base, {isFocused, isSelected}) => ({
      ...base,
      color: isFocused
      ? '#000'
      : isSelected
          ? '#000'
          : undefined,
      background: isFocused
      ? 'rgba(0, 0, 0, 0.08)'
      : isSelected
          ? 'rgba(0, 0, 0, 0.07)'
          : undefined,
      ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    })
};

  return (<div
    key={ele.name}
    className={
      `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele.name}-label-filter-name` +
      (selected ? "selected" : "") +
      (ele.disabled ? " disabled" : "")
    }
  >
    <div className="name-top" title={ele.label}>{ele.label}</div>
    <div style={{ display: 'flex' }}>
      <CreateableSelect
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        formatCreateLabel={(value) => value}
        styles={styles}
        name={accessor}
        isMulti={!ele.singleSelection}
        isClearable={false}
        defaultValue={defaultMinValue}
        value={defaultMinValue}
        options={ele.minSuggestions}
        placeholder="Min"
        onChange={e => {
          const tempMinValue = (e.value >= 0 && e.value <= 999999) ? { label: e.value, value: e.value } : defaultMinValue;
          onChangeForMin(tempMinValue);
        }}
      />
      <span style={{ display: 'flex', alignItems: 'center' }}>&nbsp;-&nbsp;</span>
      <CreateableSelect
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        formatCreateLabel={(value) => value}
        name={accessor}
        styles={styles}
        isMulti={!ele.singleSelection}
        isClearable={false}
        defaultValue={defaultMaxValue}
        value={defaultMaxValue}
        options={ele.maxSuggestions}
        placeholder="Max"
        onChange={e => {
          const tempMaxValue = (e.value >= 0 && e.value <= 999999) ? { label: e.value, value: e.value } : defaultMaxValue;
          onChangeForMax(tempMaxValue);
        }}
      />
    </div>
  </div>);
}

export default FilterMinMax;
