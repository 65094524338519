import { createContext } from "react";

const EventCommonFilter = createContext({
    event: {
        "name": "products_list",
        "data": {
            "products_type": 'base'
        }
    }
});

export default EventCommonFilter;
