import { decodeToken } from "react-jwt";
import axios from "axios";
import qs from "qs";
import config from "../config/config.js";
import { refreshAuthTokenApi } from "../components/common/constants";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  removeValueFromLocalStorage,
  setCompanyDateFromLocalStorage,
} from "./localStorageOperations";
import { toast } from "react-toastify";

const waitInLoop = (resolve) => {
  if (localStorage.getItem('checkSessionStatus') == null) {
    resolve(true);
  } else {
    setTimeout(function(){
      waitInLoop(resolve);
    }, 1000);
  }
};

export const checkSessionStatus = (idToken) => {
  const decodedObj = decodeToken(idToken);
  // time provided by Auth is in epoch (second)
  // while js uses milliseconds, hence * 1000
//   console.log('decodedObj', decodedObj);
  const expirationTime = decodedObj?.exp * 1000;
  const refresh_token = getLocalStorageValue("refresh_token");
  const tenant = getLocalStorageValue("tenant");
  const email = decodedObj?.email;
  const currentTime = Date.now();
  const refreshTime = 1 * 60 * 1000; // min * seconds * millseconds
//   console.log('countdown', (expirationTime - (currentTime + refreshTime)));
  let passThroughAccess = JSON.parse(localStorage.getItem('passThrough'))

  return new Promise((resolve) => {

    let passThrough = JSON.parse(localStorage.getItem("passThrough"));
    if(passThrough) {
      resolve(true);
      return ;
    }
    // refresh time is used to refresh auth token
    // before idToken expires
    if (!idToken || idToken === undefined || idToken === "undefined") {
      resolve(false);
    }
    if (expirationTime < (currentTime + refreshTime)) {

      if (localStorage.getItem('checkSessionStatus')) {
        waitInLoop(resolve);
      } else {
        localStorage.setItem('checkSessionStatus', '1');
        console.log('setcheckSessionStatusstorage');
        let headersObj = {
          "Authorization": "Bearer " + refresh_token,
          "X-Bungee-Tenant": tenant ? tenant : null,
          "Content-Type": "application/json", 
        };
        if(passThroughAccess && localStorage.getItem('current_location')){
          headersObj["X-Tenant-Location"] = localStorage.getItem('current_location');
        }

        axios({
          method: "POST",
          url: config.base_url + refreshAuthTokenApi,
          headers: headersObj,
          credentials: "omit",
          data: {"refreshToken" :  refresh_token}
        })
          .then((response) => {
            console.log('response.status', response.status);
            if (response.status === 200) {
              setLocalStorageValue("access_token", response.data.data.access_token);
              setLocalStorageValue("id_token", response.data.data.id_token);
            } else {
              alert("Some error ocurred");
            }
            localStorage.removeItem('checkSessionStatus');
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            localStorage.removeItem('checkSessionStatus');
            toast.info("Current Session has been expired!, please login again, redirecting to login page!");
            setTimeout(() => {
              localStorage.clear()
              resolve(false);
            }, 5000);
            let passThrough = JSON.parse(localStorage.getItem('passThrough'))
            if(passThrough){
              window.location.href = config.pmtEndPoint;
            }
        });
    }

    } else {
      resolve(true);
    }
  });
};
