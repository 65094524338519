import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (process.env.REACT_APP_NODE_ENV != 'local') {
  console.log = function() {}
}

window.addEventListener("error", (event) => {
  console.log('event', event);
  if (process.env.REACT_APP_NODE_ENV != 'local' && process.env.REACT_APP_NODE_ENV != 'dev') {
    window.location.pathname = "/error";
  }
});