import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const FilterNumber = (props) => {
    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        filterBySelectedValues,
    } = props;
    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const range = ele?.range;
    const [defaultValue, setDefaultValue] = useState(Array.from(filterBtnsSelected[accessor]));

    const onChangeEvent = (value) => {
        setDefaultValue(value);
        let copySet = new Set([value]);
        filterBySelectedValues({ [ele.name]: copySet });
    };

    return (<div
        key={ele.name}
        title={ele.label}
        className={
          `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele.name}-label-filter-name` +
          (selected ? "selected" : "") +
          (ele.disabled ? " disabled" : "")
        }
      >
          <div className="name-top">{ele.label}</div>
          <Form.Control 
            type="number" 
            min={ele.min} 
            value={defaultValue}
            placeholder="NA"
            onChange={e => {
                onChangeEvent(e.target.value);
            }}
            // onKeyUp={e => {
            //     if (e.key === "Enter") {
            //         onChangeEvent(e.target.value);
            //     }
            // }}
          />
      </div>);
};

export default FilterNumber;