import React, { useState, useEffect } from "react";
import { decodeToken } from "react-jwt";
import { Link } from "react-router-dom";
import { apiCall, isOnlyDigits } from "../common/common";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { signUpApi, verifyEmailApi } from "../common/constants";
import { getLocalStorageValue } from "../../utils/localStorageOperations";
import { checkSessionStatus } from "../../utils/loginSessionOperations";
import * as pageRoutes from "../../pageRoutes.js";

import "./signup.scss";
import LoginFooter from "../Login/LoginFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const errorMsgs = {
  success: "User registered successfully! Check your Email for verification code.",
};

const Signup = (props) => {
  const navigate = useNavigate();
  const [registrationForm, setRegistrationForm] = useState(true);
  const [verificationForm, setVerificationForm] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    code: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    var validEmail = re.test(email);
    if (validEmail) {
      let atSplit = email.split("@");
      if (atSplit.length >= 3) {
        return false;
      }
      atSplit = atSplit.pop();
      atSplit = atSplit.split(".");
      if (atSplit.length >= 4) {
        return false;
      }
    }
    return validEmail;
  };

  function checkPassword(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/\\><':;|_~`+=-])[A-Za-z\d\^$*.[\]{}()?\-"!@#%&/\\><':;|_~`+=-]{8,}$/;
    
    // Test the password against the regular expression
    return regex.test(password);
  }


  const sendSignupDetailsToServer = () => {
    console.log(state);
    if (
      state.name.length &&
      state.email.length &&
      state.password.length &&
      checkPassword(state.password) &&
      state.code.trim().length &&
      validateEmail(state.email)
    ) {
      // props.showError(null);
      const payload = {
        emailAddress: state.email,
        registrationCode: state.code.trim(),
        password: state.password,
        name: state.name,
        //country: 'United States'
      };
      setErrorMsg("");
      setSuccessMsg("");
      setLoading(true);
      // props.history.push("/");
      // axios.post(API_BASE_URL+'register', payload)
      apiCall("POST", signUpApi, "", payload)
        .then(function (response) {
          console.log(response.data);
          setLoading(false);
          setSuccessMsg(errorMsgs.success);
          setRegistrationForm(false);
          setVerificationForm(true);
          setState((prevState) => ({
            ...prevState,
            name: "",
            password: "",
            code: "",
          }));
          window.gtag("set", "user_properties", {
            user_email_dimension: state.email,
            // user_tenant_dimension: state.company,
          });
          window.gtag("event", "sign_up", {
            email: state.email,
            // company: state.company,
          });
        })
        .catch(function (error) {
          const errorResponse = error?.response?.data;
          console.log(errorResponse);
          if (
            errorResponse?.message &&
            "errorMessage" in errorResponse.message
          ) {
            let errors = [];
            errors.push(errorResponse.message.errorMessage);
            setErrorMsg(errors);
          } else {
            setErrorMsg(errorResponse);
          }
          setLoading(false);
        });
      }
      else if (!checkPassword(state.password)) { 
      setErrorMsg("Password should contain Uppercase , Lowercase , Number , Special (@, $, !, %, *, ?, or &) and Minimun 8 characters.");
    } 
    else if (!validateEmail(state.email)) { 
      setErrorMsg("Please enter valid Email.");
    } else {
      setErrorMsg("You must fill in all of the fields.");
    }
  };
  const VerifyCode = () => {
    setSuccessMsg("");
    if (state.email.length && state.code.trim().length && isOnlyDigits(state.code.trim())) {
      const payload = {
        emailAddress: state.email,
        confirmationCode: state.code.trim(),
      };
      setLoading(true);
      apiCall("POST", verifyEmailApi, "", payload)
        .then(function (response) {
          console.log(response.data);
          setLoading(false);
          setErrorMsg("");
          setSuccessMsg("User registration completed! Redirecting to Sign in page...");
          setTimeout(() => {
            window.location.href = pageRoutes.loginPage;
          }, 5000);
        })
        .catch(function (error) {
          const errorResponse = error?.response?.data;
          if (
            errorResponse?.message &&
            "errorMessage" in errorResponse.message
          ) {
            let errors = [];
            errors.push(errorResponse.message.errorMessage);
            setErrorMsg(errors);
          } else {
            setErrorMsg(errorResponse);
          }
          setLoading(false);
        });
    } else {
      setErrorMsg("Please enter valid code");
    }
  };

  useEffect(() => {
    const idToken = getLocalStorageValue("id_token");
    const decodedObj = decodeToken(idToken);
    const expirationTime = decodedObj?.exp * 1000;
    const currentTime = Date.now();
    if (expirationTime >= currentTime) {
      checkSessionStatus(idToken).then((value) => {
        if (value) {
          navigate(pageRoutes.dashboardPage);
        }
      });
    } else {
      document.title = "Retailscape - Register";
    }
  }, [navigate]);

  return (
    <div className="auth-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center loginPage">
          <div className="col-4">
            <div className="login-logo"></div>
            <div className="row pad">
              <div className="col-md-12 text-start">
                <ul id="signinErrorList">
                  {Array.isArray(errorMsg)
                    ? errorMsg.map((ele) => <li className="alert alert-danger">{ele}</li>)
                    : errorMsg && <li className="alert alert-danger">{errorMsg}</li>}
                  {Array.isArray(successMsg)
                    ? successMsg.map((ele) => <li className="alert alert-success">{ele}</li>)
                    : successMsg && <li className="alert alert-success">{successMsg}</li>}
                </ul>
                <h1 className="display-4 text-left mb-4 fw-bolder">
                  Start Your Journey with us!
                </h1>
                <p className="text-muted text-left mb-5">
                  Discover Bungee Retailscape Analytics Tool.
                </p>
                <form>
                  {registrationForm && (
                    <div>
                      <div className="input-group-lg mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Name *"
                          name="name"
                          onChange={handleChange}
                          required
                        />
                      </div>

                      {/* <div className="input-group-lg mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company name"
                          name="company"
                          onChange={handleChange}
                        />
                      </div> */}

                      <div className="input-group-lg mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Registration Code *"
                          name="code"
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="input-group-lg mb-4">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email *"
                          name="email"
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="input-group-lg mb-4">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Password *"
                          name="password"
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="form-check p-0 mb-1">
                        <div className="float-start">
                          <input
                            type="checkbox"
                            className="me-2"
                            checked={true}
                            disabled
                          />
                        </div>
                        <label className="form-check-label" >
                          I Agree to 
                          &nbsp;<span className="btn-link text-decoration-none cursor-pointer" onClick={e => setShowPrivacyPolicy(true)}>Privacy Policy</span> 
                          &nbsp;&nbsp;<a target="_blank" href={process.env.PUBLIC_URL + "/privacy-policy.pdf"}><FontAwesomeIcon icon={faFileDownload}/></a>
                        </label>
                      </div>

                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="btn btn-lg btn-dark btn-block mb-4"
                          onClick={sendSignupDetailsToServer}
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                  )}
                  {
                  verificationForm && (
                    <div>
                      <div className="input-group-lg mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Code"
                          name="code"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary btn-block mb-4"
                          onClick={VerifyCode}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  )
                  }
                  <p className="forgot-password text-center">
                    Already have an account? <Link to="/" className="text-decoration-none">Sign In</Link>
                  </p>
                </form>
              </div>
              <div className="col-md-12">
                <p className="text-center login-footer">
                  <LoginFooter showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy}  />
                </p>
              </div>
            </div>
            <Loader loading={loading} />
          </div>
          <div className="col-8 d-none d-lg-block pe-0">
            <div className="h-100">
              <div className="bg-cover-container"><div className="bg-cover"></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
