import React from "react";
import Routess from "./Routess";

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css';

function App() {
  return (
    <div id="parent-container">
      <Routess />
    </div>
  );
}

export default App;
