import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { copyrightYear, versionName } from "../common/common";

const LoginFooter = (props) => {

    const {
        showPrivacyPolicy,
        setShowPrivacyPolicy
    } = props;

    const handleClose = () => {
        setShowPrivacyPolicy(false);
    };

    const privacyPolicy = () => {
        return (<Modal
            size="lg"
            show={showPrivacyPolicy}
            onHide={handleClose}
            centered
            className="privacy-policy-modal"
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    <div className="title">
                        <h3 className="mb-0">BUNGEE - PRIVACY POLICY </h3>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                <p>At Bungee Tech, we are committed to protecting the privacy of our customers and users of our service. This Privacy Policy outlines the information we collect, how it is used, and the steps we take to ensure that your information is protected.</p>
                <p>Bungee Tech offer a variety of services to our customers. Our main service is a data analytics tool that provides customers with insights and information about their industry, including competitor product matching and pricing crawling data.</p>
                <p>Our tool allows customers to view and analyze data in real-time, providing them with the information they need to make informed business decisions. This privacy policy covers every Bungee tool that links here, and all of the products and services contained on those tools.</p>
                <p>This Privacy Policy is divided into three parts:</p>
                <p>
                    <h5>Part I - Information Bungee collects and processes as a business</h5>
                    This part deals with how Bungee collects and uses information about XXX
                </p>
                <p>
                    <h5>Part II - Information that Bungee processes as a service provider</h5>
                    This part deals with how Bungee handles data that you entrust to us when you use our products and services,
                </p>
                <p>
                    <h5>Part III - General</h5>
                    This part deals with topics that are relevant to both Parts I and II, and other general topics such as Bungee's security commitments and how we will inform you when we change this Privacy Policy.
                </p>
                <hr/>

                <p>
                    <h4>Part I - Information Bungee collects and processes as a business</h4>
                    <table className="table table-bordered text-center align-middle">
                        <tbody>
                            <tr>
                                <td rowSpan={2}><strong>Categories of Personal Information</strong></td>
                                <td><strong>Collection</strong></td>
                                <td><strong>Disclosure</strong></td>
                            </tr>
                            <tr>
                                <td><strong>What do we collect</strong></td>
                                <td><strong>Disclosure for a business purpose (analytics & service Improvement)</strong></td>
                            </tr>
                            <tr>
                                <td>A</td>
                                <td>Identifiers: User Name</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>B</td>
                                <td>Company Name</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>Registration code</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>D</td>
                                <td>Email</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>Password</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td>Internet or other electronic network activity information</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td>Geolocation data</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td>Audio, visual or similar information</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>I</td>
                                <td>Analytics information</td>
                                <td>YES</td>
                            </tr>
                        </tbody>
                    </table>
                </p>
                <p>
                    <h5>What information Bungee collects</h5>
                    We collect information about you only if we need the information for some legitimate purpose. Bungee will have information about you only if (a) you have provided the information yourself, (b) Bungee has automatically collected the information, or (c) Bungee has obtained the information from a third party. Below we describe the various scenarios that fall under each of those three categories and the information collected in each one. Personal information collected by us in the 12 months preceding this Privacy Policy's last update are also consistent with the following.
                </p>
                
                <p>
                    <h5>Information that you provide us</h5>
                    <ol type="i">
                        <li>Account signup : When you sign up for an account to access one or more of our services, we ask for information like your name, email address, company name to complete the account signup process, we provide the registration code from our end. You'll also be required to choose a unique username and a password for accessing the created account.</li>
                        <li>Interactions with Bungee : We may record, analyze and use your interactions with our tools & applications to Improve our services</li>
                    </ol>   
                </p>
                
                <p>
                    <h5>Information that we collect automatically</h5>
                    <ol type="i">
                        <li>Information from browsers, devices and servers : When you visit our application over a browser, we collect information that web browsers, and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system. We include these in our log files to understand more about visitors to our websites.</li>
                        <li>Information from cookies and tracking technologies :We use temporary and permanent cookies to identify users of our services and to enhance user experience. We embed unique identifiers in our products to track usage of the products.</li>
                        <li>Information from application logs and analytics: We collect information about your use of our products, services  from application logs and in-house usage analytics tools, and use it to understand how your use and needs can improve our products. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, storage utilized, user settings and configurations, and devices used to access and their locations.</li>
                    </ol>
                </p>
                
                <p>
                    <h5>Purposes for using information</h5>
                    In addition to the purposes mentioned above, we may use your information for the following purposes:
                    <ul>
                        <li>To communicate with you (such as through email) about services that you have signed up for, changes to this Privacy Policy, changes to the Terms of Service, or important notices.</li>
                        <li>To keep you posted on new products and services, upcoming events, offers, promotions and other information that we think will be of interest to you.</li>
                        <li>To ask you to participate in surveys, or to solicit feedback on our products and services.</li>
                        <li>To set up and maintain your account, and to do all other things required for providing our services, such as enabling collaboration, providing website and mail hosting, and backing up and restoring your data.</li>
                        <li>To understand how users use our products and services, to monitor and prevent problems, and to improve our products and services.</li>
                        <li>To provide customer support, and to analyze and improve our interactions with customers.</li>
                        <li>To detect and prevent fraudulent transactions and other illegal activities, to report spam, and to protect the rights and interests of Bungee, and it’s users, third parties and the public.</li>
                        <li>To update, expand and analyze our records, identify new customers, and provide products and services that may be of interest to you.</li>
                    </ul>
                </p>

                <p>
                    <h5>Your choice in information use</h5>
                    Opt out of non-essential electronic communications: You may opt out of receiving newsletters and other non-essential messages by using the ‘unsubscribe' function included in all such messages. However, you will continue to receive essential notices and emails such as account notification emails (password change, renewal reminders, etc.), security incident alerts, security and privacy update notifications, and essential transactional and payment related emails.
                    <br/>Disable cookies : You can disable browser cookies before visiting our websites. However, if you do so, you may not be able to use certain features of the websites properly.
                </p>

                <p>
                    <h5>Retention of information</h5>
                    We retain your personal information for as long as it is required for the purposes stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply with other legal obligations. When we no longer have a legitimate need to process your information, we will delete or anonymize your information from our active databases. We will also securely store the information and isolate it from further processing on backup discs until deletion is possible.
                </p>
                <hr/>

                <p>
                    <h4>Part II - Information that Bungee processes on your behalf:</h4>
                    <h5>Information entrusted to Bungee and purpose</h5>
                    Information provided in connection with services : You may entrust information that you or your organization (“you”) control, to Bungee in connection with use of our services or for requesting technical support for our products. This includes information regarding your customers and your employees (if you are a controller) or data that you hold and use on behalf of another person for a specific purpose, such as a customer to whom you provide services (if you are a processor). The data may either be stored on our servers when you use our services, or transferred or shared to us as part of a request for technical support or other services.
                </p>
                <hr/>

                <p>
                    <h4>Part III - General</h4>
                    <h5>Notification of changes</h5>
                    We may modify the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. If we make significant changes to the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of the changes by email to your primary email address. However, if you have not verified your email address, you may miss important notifications that we send through email. If you think that the updated Privacy Policy affects your rights with respect to your use of our products or services, you may inform us by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email notification of minor changes to the Privacy Policy.
                </p>
            </Modal.Body>
        </Modal>);
    };

    return (<>
        <small>Copyright @Bungee {copyrightYear()} | Version {versionName()} {/*| <span className="cursor-pointer" onClick={e => setShowPrivacyPolicy(false)} >Privacy Policy</span>*/}</small>
        {privacyPolicy()}
    </>);
};

export default LoginFooter;