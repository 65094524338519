import React, { useEffect, useState, useContext } from "react";
import ReactPlotly from "../common/ReactPlotly/ReactPlotly";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import { Link, useNavigate } from "react-router-dom";
import * as pageRoutes from "../../pageRoutes";
import { NoDataMessage } from "../common/constants";

const UbrGapsGraphBarChart = (props) => {
    const {
        filterBtnsSelected,
        setFilterBtnsSelected,
        sideMenuCommonFilterContext,
        setSideMenuCommonFilterContext,
        data,
        currentCategory,
        setCurrentRetailer,
    } = props;
    const navigate = useNavigate();

    const [plotlyData, setPlotlyData] = useState([]);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.retailerLib) {
            let newPlotlyData = [];
            let barChartIndexes = {
                'ubrGapCount': 'UBR Gaps',
            };
            
            if (data) {
                let filterData = {};
                let merch1Category1Lib = sideMenuCommonFilterLibContext.merch1Category1Lib;
                let newData = data;
                /*if (currentCategory != 'all') {
                    newData = data.filter(dataSingle => {
                        return (merch1Category1Lib[dataSingle.merch1_category_level_1_id] == currentCategory);
                    });
                    console.log('newDatanewData', newData);
                }*/
                newData.map(singleData => {
                    //console.log('singleData newData', singleData, sideMenuCommonFilterLibContext.retailerLib);
                    if (singleData.source_id in sideMenuCommonFilterLibContext.retailerLib) {
                        let retailerLabel = sideMenuCommonFilterLibContext.retailerLib[singleData.source_id];
                        if (!(retailerLabel in filterData)) {
                            filterData[retailerLabel] = {
                                'allIndex' : 0,
                                'retailerId': singleData.source_id,
                                'retailerLabel': retailerLabel
                            };
                            Object.keys(barChartIndexes).map(barChartIndex => {
                                filterData[retailerLabel][barChartIndex] = 0;
                            });
                        }
                        Object.keys(barChartIndexes).map(barChartIndex => {
                            filterData[retailerLabel][barChartIndex] += singleData[barChartIndex];
                            filterData[retailerLabel]['allIndex'] += singleData[barChartIndex];
                        });
                    }
                });

                
                let sortOderRetailer = Object.values(filterData).sort((a, b) => {
                    return b.allIndex - a.allIndex ;
                });
                sortOderRetailer = sortOderRetailer.filter(sortOderRetailerSingle => {
                    return sortOderRetailerSingle.allIndex;
                }).map(sortOderRetailerSingle => {
                    return sortOderRetailerSingle.retailerLabel;
                });
    
                Object.keys(barChartIndexes).map(barChartIndex => {
                    let newBarPart = {
                        x: sortOderRetailer,
                        y: sortOderRetailer.map(sortOderRetailerSingle => {
                            return filterData[sortOderRetailerSingle][barChartIndex]
                        }),
                        name: barChartIndexes[barChartIndex],
                        type: 'bar',
                        marker: {color: '#ececec'},
                        width: 0.2,
                        opacity: 0.8
                    };
                    newPlotlyData.push(newBarPart);
                });
            }
    
            setPlotlyData(newPlotlyData);
        }
    }, [data, currentCategory, sideMenuCommonFilterLibContext]);

    const onClickPlotly = (event) => {
        if (event.points.length) {
            let point = event.points[0];
            if (point.x) {
                console.log('hello', point.x);
                setCurrentRetailer(point.x);
                /*let redirectPath = pageRoutes.productGapsUniqueByRetailersPage + "?retailer=" + point.x;
                if (currentCategory != 'all') {
                    redirectPath += "&category=" + currentCategory;
                }*/
                setCategories(point.x);
                navigate(pageRoutes.productGapsUniqueByRetailersPage);
            }
        }
    };

    const setCategories = (retailer) => {
        let newFilterBtnsSelected = {...filterBtnsSelected};
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext};
        newFilterBtnsSelected.retailer = newSideMenuCommonFilterContext.retailer  = new Set([retailer]);
        if (currentCategory != 'all') {
            let categories = currentCategory.split(' - ');
            newFilterBtnsSelected.category = newSideMenuCommonFilterContext.category  = new Set([categories[0]]);
            newFilterBtnsSelected.subcategory = newSideMenuCommonFilterContext.subcategory  = new Set([categories[1]]);
            newFilterBtnsSelected.subsubcategory = newSideMenuCommonFilterContext.subsubcategory  = new Set([categories[2]]);
            newFilterBtnsSelected.subsubsubcategory = newSideMenuCommonFilterContext.subsubsubcategory  = new Set([categories[2]]);
        }
        setFilterBtnsSelected(newFilterBtnsSelected);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    };

    return (<div className="border-right-light-grey h-100">
        {(plotlyData.length && plotlyData[0]['x'].length == 0) ? <div className="display-5 mt-5 text-center">{NoDataMessage}</div> : <ReactPlotly
            data={plotlyData}
            layout={{
                margin: {l: 30, r: 30, t: 0},
                yaxis: {
                    type: 'log',
                    autorange: true,
                    tickmode: "linear"
                },
                barmode: 'stack', 
                hovermode: 'x unified', 
                //title: (currentCategory == "all") ? 'All Category UBR Gaps' : (currentCategory + ' UBR Gaps'),
                //yaxis: {tickformat: ',.2%', range: [0,1]}
            }}
            config={{responsive: true}}
            useResizeHandler={true}
            style={{width: '100%', height: '100%'}}
            onClick={onClickPlotly}
        />}
    </div>);
};

export default UbrGapsGraphBarChart;