import devConfigs from "./envs/dev.config.json";
import localConfigs from "./envs/local.config.json";
import stageConfigs from "./envs/stage.config.json";
import demoConfigs from "./envs/demo.config.json";
import prodConfigs from "./envs/prod.config.json";

const configs = {
    dev: devConfigs,
    'non-prod': devConfigs,
    local: localConfigs,
    stage: stageConfigs,
    demo: demoConfigs,
    prod: prodConfigs
}
const config = configs[process.env.REACT_APP_NODE_ENV || "dev"];

export default config;